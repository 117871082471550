import { AfterViewChecked, Component, TemplateRef, ViewChild } from '@angular/core';

import { CamRoutes, Menu } from '@camelot/menu';

import { BaseComponent } from 'src/app/modules/core/abstract/baseComponent';
import { EUserRoute } from 'src/app/modules/user/routing.module';
import { AppMenuService } from 'src/app/services/menus.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-main-menu',
  templateUrl: './main-menu.component.html',
  styleUrls: ['./main-menu.component.scss'],
})
export class MainMenuComponent extends BaseComponent implements AfterViewChecked {
  public menu = this._menuService.getMainMenu();
  public userMenu: Menu | null = null;
  public infoMenu: Menu | null = null;

  get appVersion() {
    return 'Version : ' + `${environment.version}`;
  }

  @ViewChild('accountTemplate') accountTemplate!: TemplateRef<any>;
  @ViewChild('notificationTemplate') notificationTemplate!: TemplateRef<any>;
  @ViewChild('notificationCounterTemplate') notificationCounterTemplate!: TemplateRef<any>;

  constructor(private _menuService: AppMenuService) {
    super();
  }

  ngAfterViewChecked() {
    this.userMenu = this._menuService.getUserMenu(
      this.accountTemplate,
      this.notificationTemplate,
      this.notificationCounterTemplate
    );
    this.infoMenu = this._menuService.getUserInfosMenu();
  }

  public toMyProfile() {
    this._router.navigateByUrl(CamRoutes.getAbsoluteUrl([EUserRoute.myProfile]));
  }

  public goToEditProfile() {
    this._router.navigateByUrl(CamRoutes.getAbsoluteUrl([EUserRoute.myProfile, EUserRoute.editMyProfile]));
  }
  public goToNotification() {
    this._router.navigateByUrl('notifications');
  }

  public mergeMenu(menu: Menu, userMenu: Menu) {
    return new Menu({
      elements: [...menu.elements, ...userMenu.elements],
      direction: 'responsive',
    });
  }
}
