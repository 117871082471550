import { GraphSchema } from '@camelot/server';

export interface ProgressStatements {
  id: number;
  quotationVersionId: string;
  isFinal: boolean;
  isDeposit: boolean;
}

export const progressStatementsProps = new GraphSchema<ProgressStatements>([
  'id',
  'quotationVersionId',
  'isFinal',
  'isDeposit',
]);
