import { Component } from '@angular/core';

import { Menu } from '@camelot/menu';

import { BasePage } from 'src/app/modules/core/abstract/basePage';
import { AppMenuService } from 'src/app/services/menus.service';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '',
  templateUrl: './legal.component.html',
  styleUrls: ['./legal.component.scss'],
})
export class LegalPage extends BasePage {
  public menu: Menu | null = null;

  constructor(private _menuService: AppMenuService) {
    super();
    this.menu = this._menuService.getUserInfosMenu();
  }
}
