import { Component, Input } from '@angular/core';

import { IPrice } from '../../helpers/price';

@Component({
  selector: 'app-billing-complex-amount',
  templateUrl: './complex-amount.component.html',
  styleUrls: ['./complex-amount.component.scss'],
})
export class ComplexAmountComponent {
  @Input()
  amount!: IPrice;
}
