import { Routes } from '@angular/router';

import { CamRoutes } from '@camelot/menu';

import { ListPage } from './pages/list/list.component';
import { FilesDashboardPage } from './pages/view/files/dashboard/dashboard.component';
import { FilesDocumentsPage } from './pages/view/files/documents/documents.component';
import { FilesPicturesPage } from './pages/view/files/pictures/pictures.component';
import { InvoicesPage } from './pages/view/invoices/invoices.component';
import { QuotationsPage } from './pages/view/quotations/quotations.component';
import { SummaryPage } from './pages/view/summary/summary.component';
import { ViewPage } from './pages/view/view.component';

export enum EProjectsRoute {
  projects = 'projects',
  list = 'list',
  view = 'view',
  summary = 'summary',
  quotations = 'quotations',
  execution = 'execution',
  tasks = 'tasks',
  invoices = 'invoices',
  files = 'files',
  dashboard = 'dashboard',
  documents = 'documents',
  pictures = 'pictures',
}

export class AppProjectsRoutingModule {
  public static loadRoutes() {
    CamRoutes.addRoute({
      key: EProjectsRoute.projects,
      url: 'projects',
      children: [
        {
          key: EProjectsRoute.list,
          url: 'list',
        },
        {
          key: EProjectsRoute.view,
          url: 'view/:id',
          children: [
            {
              key: EProjectsRoute.summary,
              url: 'summary',
            },
            {
              key: EProjectsRoute.quotations,
              url: 'quotations',
            },
            {
              key: EProjectsRoute.execution,
              url: 'execution',
            },
            {
              key: EProjectsRoute.tasks,
              url: 'tasks',
            },
            {
              key: EProjectsRoute.invoices,
              url: 'invoices',
            },
            {
              key: EProjectsRoute.files,
              url: 'files',
            },
            {
              key: EProjectsRoute.documents,
              url: 'documents',
            },
            {
              key: EProjectsRoute.pictures,
              url: 'pictures',
            },
          ],
        },
      ],
    });
  }

  public static getRouting(): Routes {
    return [
      {
        path: '',
        redirectTo: CamRoutes.getUrl([EProjectsRoute.projects, EProjectsRoute.list]),
        pathMatch: 'full',
      },
      {
        path: CamRoutes.getUrl([EProjectsRoute.projects, EProjectsRoute.list]),
        component: ListPage,
      },
      {
        path: CamRoutes.getUrl([EProjectsRoute.projects, EProjectsRoute.view]),
        component: ViewPage,
        children: [
          {
            path: CamRoutes.getUrl([EProjectsRoute.projects, EProjectsRoute.view, EProjectsRoute.summary]),
            component: SummaryPage,
          },
          {
            path: CamRoutes.getUrl([EProjectsRoute.projects, EProjectsRoute.view, EProjectsRoute.quotations]),
            component: QuotationsPage,
          },
          {
            path: CamRoutes.getUrl([EProjectsRoute.projects, EProjectsRoute.view, EProjectsRoute.invoices]),
            component: InvoicesPage,
          },
          {
            path: CamRoutes.getUrl([EProjectsRoute.projects, EProjectsRoute.view, EProjectsRoute.files]),
            component: FilesDashboardPage,
          },
          {
            path: CamRoutes.getUrl([EProjectsRoute.projects, EProjectsRoute.view, EProjectsRoute.documents]),
            component: FilesDocumentsPage,
          },
          {
            path: CamRoutes.getUrl([EProjectsRoute.projects, EProjectsRoute.view, EProjectsRoute.pictures]),
            component: FilesPicturesPage,
          },
        ],
      },
    ];
  }
}
