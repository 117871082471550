import { Injectable } from '@angular/core';

import { filter, map, mergeMap, of, switchMap } from 'rxjs';

import { CamBaseService, GraphEndpoint, HandleComplexRequest, HandleSimpleRequest } from '@camelot/server';
import { getUniqueArray, isNonNullable, keepUniqueObjectByProperty } from '@camelot/utils';

import { AppProjectsService } from '../projects/projects.service';
import { DocumentDto } from './dto/document';
import { GET_DOCUMENTS, GET_DOCUMENT_BY_PROJECT_ID } from './queries';

const graphEndpoint: GraphEndpoint = {
  clientName: 'documentService',
  endpoint: 'document',
};

@Injectable({
  providedIn: 'root',
})
export class AppDocumentsService extends CamBaseService {
  public dashboard = new HandleSimpleRequest<DocumentDto[]>();

  public documents = new HandleSimpleRequest<DocumentDto[]>();

  public dashboardByProject = new HandleComplexRequest<DocumentDto[]>();
  public documentByProject = new HandleComplexRequest<DocumentDto[]>();

  constructor(private _projectService: AppProjectsService) {
    super();
    super.registerRoutes({ graphEndpoint: graphEndpoint });
  }

  public fetchDocuments$(ids: string[]) {
    return this.documents.fetch(
      this._graphService
        .fetchPagedQueryList<DocumentDto>(GET_DOCUMENTS({ ids }), 'documents', graphEndpoint.clientName)
        .pipe(
          map(data => data.items ?? []),
          filter(isNonNullable),
          map(list => [...(list ?? []), ...(this.documents.get() ?? [])]),
          map(list => keepUniqueObjectByProperty(list, item => item.id))
        )
    );
  }

  public fetchDashboard$() {
    return this.dashboard.fetch(
      this._graphService
        .fetchPagedQueryList<DocumentDto>(GET_DOCUMENTS({ take: 4 }), 'documents', graphEndpoint.clientName)
        .pipe(
          map(data => data.items),
          filter(isNonNullable),
          switchMap(list =>
            this._projectService
              .getProjectsLightInfo$(getUniqueArray(list.map(item => item.projectId).filter(isNonNullable)))
              .pipe(
                map(projects =>
                  list.map(item => ({
                    ...item,
                    ...{
                      project: projects.find(project => project.id === item.projectId),
                    },
                  }))
                )
              )
          )
        )
    );
  }

  public fetchDashboardByProjects$(id: string) {
    return this.dashboardByProject.fetch(
      id,
      this._graphService
        .fetchPagedQueryList<DocumentDto>(
          GET_DOCUMENT_BY_PROJECT_ID(id, 5),
          'documentsByProject',
          graphEndpoint.clientName
        )
        .pipe(map(data => data.items ?? []))
    );
  }

  public fetchDocumentByProjects$(id: string) {
    return this.documentByProject.fetch(
      id,
      this._graphService
        .fetchPagedQueryList<DocumentDto>(
          GET_DOCUMENT_BY_PROJECT_ID(id),
          'documentsByProject',
          graphEndpoint.clientName
        )
        .pipe(map(data => data.items ?? []))
    );
  }
}
