<app-layout-second-level *ngLet="this.project$ | async as project">
  <app-layout-title> {{ project?.name }} </app-layout-title>
  <app-layout-content>
    <app-projects-navigation [projectId]="this.projectId"></app-projects-navigation>
    <div class="mt-space-lg">
      <cam-loader [isLoading]="this.requestState.isLoading()">
        <cam-error [message]="this.requestState.getErrorMessage()" [code]="this.requestState.getErrorStatus()">
          <router-outlet></router-outlet>
        </cam-error>
      </cam-loader>
    </div>
  </app-layout-content>
</app-layout-second-level>
