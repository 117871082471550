import { HttpErrorResponse } from '@angular/common/http';
import { Component } from '@angular/core';

import { map } from 'rxjs';

import { CamRoutes } from '@camelot/menu';

import { EBillingsRoute } from 'src/app/modules/billings/routing.module';
import { BasePage } from 'src/app/modules/core/abstract/basePage';
import { PaymentStatus } from 'src/app/services/billings/dto/invoice/payment-status';
import { AppInvoicesService } from 'src/app/services/billings/invoices.service';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '',
  templateUrl: './invoices.component.html',
  styleUrls: ['./invoices.component.scss'],
})
export class InvoicesPage extends BasePage {
  private _projectId: string = '';

  get invoices$() {
    return this._invoicesService.invoicesByProject.get$(this._projectId);
  }

  get invoicesToPay$() {
    return this.invoices$.pipe(
      map(invoices => invoices.filter(invoice => !invoice.isCreditNote && invoice.paymentStatus !== PaymentStatus.Paid))
    );
  }

  get invoicesPaid$() {
    return this.invoices$.pipe(
      map(invoices => invoices.filter(invoice => !invoice.isCreditNote && invoice.paymentStatus === PaymentStatus.Paid))
    );
  }

  get creditNotes$() {
    return this.invoices$.pipe(map(invoices => invoices.filter(invoice => invoice.isCreditNote)));
  }

  constructor(private _invoicesService: AppInvoicesService) {
    super();

    if (this._route.parent) {
      this._registerSubscription(
        this._route.parent.params.subscribe(parentParams => {
          this._projectId = parentParams['id'];
          this._fetch();
        })
      );
    }
  }

  public navigateToDetail(id: string) {
    this._router.navigateByUrl(
      CamRoutes.getAbsoluteUrl([EBillingsRoute.billing, EBillingsRoute.invoices, EBillingsRoute.view], { id: id })
    );
  }

  private _fetch() {
    this.requestState.asked();
    this._registerSubscription(
      this._invoicesService.fetchInvoicesByProject$(this._projectId).subscribe({
        complete: () => this.requestState.completed(),
        error: (error: HttpErrorResponse) => {
          this.requestState.onError(error.status, error.statusText);
        },
      })
    );
  }
}
