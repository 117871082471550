import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { filter, switchMap } from 'rxjs/operators';

import { Observable } from 'rxjs';

import { environment } from 'src/environments/environment';

import { AzureAuthService } from './../services/azure-auth.service';

@Injectable()
export class AzureInterceptor implements HttpInterceptor {
  constructor(private azureAuthService: AzureAuthService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const allowedUri = environment.AADB2C.apiConfig.uri;

    if (req.url.startsWith(allowedUri)) {
      return this.azureAuthService.getTokenFromCache().pipe(
        filter(result => result !== null),
        switchMap(result => {
          const authReq = req.clone({ headers: req.headers.set('Authorization', `Bearer ${result.idToken}`) });
          return next.handle(authReq);
        })
      );
    }

    return next.handle(req);
  }
}
