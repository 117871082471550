<div class="ref-container" (click)="this.toggle()">
  <div class="rect" [ngClass]="this.getClasses()"></div>
  <div class="space-between align-center">
    <ng-container *ngIf="this.haveChildren">
      <cam-font-icon name="arrow-big-right" *ngIf="!this.isExpanded"></cam-font-icon>
      <cam-font-icon name="arrow-big-bottom" *ngIf="this.isExpanded"></cam-font-icon>
    </ng-container>
    <div [class.space]="!this.haveChildren">
      {{ this.value }}
    </div>
  </div>
</div>
