import { inject } from '@angular/core';

import { Menu } from '@camelot/menu';

import { BasePage } from 'src/app/modules/core/abstract/basePage';
import { AppMenuService } from 'src/app/services/menus.service';

export class AppBaseFilesPage extends BasePage {
  public projectId: string = '';
  public switchMenu: Menu | null = null;

  private _menuService = inject(AppMenuService);

  constructor() {
    super();

    this._registerSubscription(
      this._getParentParams<{ id: string }>({ id: '' }).subscribe(params => {
        this.projectId = params.id;
        this.switchMenu = this._menuService.getProjectFilesMenu(this.projectId);
      })
    );
  }
}
