import { Component } from '@angular/core';

import { CamRoutes } from '@camelot/menu';

import { BaseComponent } from '../../../core/abstract/baseComponent';
import { EUserRoute } from '../../routing.module';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '',
  templateUrl: './my-profile.component.html',
  styleUrls: ['./my-profile.component.scss'],
})
export class MyProfilePage extends BaseComponent {
  public goToModify() {
    this._router.navigateByUrl(CamRoutes.getAbsoluteUrl([EUserRoute.myProfile, EUserRoute.editMyProfile]));
  }
}
