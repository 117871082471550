import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { AppRedirectService } from 'src/app/services/redirect.service';

@Injectable({
  providedIn: 'root',
})
export class HomeGuard {
  constructor(private redirectService: AppRedirectService, private router: Router) {}

  canActivate(): boolean {
    const urlPath = this.redirectService.getUrlPath();
    if (urlPath) {
      this.redirectService.setUrlPath(null);
      this.router.navigateByUrl(urlPath);
      return false;
    }
    return true;
  }
}
