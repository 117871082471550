import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { CamLayoutModule } from '@camelot/ui';
import { CamUserModule } from '@camelot/user';
import { CamDirectivePipeModule } from '@camelot/utils';

import { HeaderComponent } from './header/header.component';

@NgModule({
  declarations: [HeaderComponent],
  imports: [CamDirectivePipeModule, CamLayoutModule, CamUserModule, CommonModule],
  exports: [HeaderComponent],
})
export class AppCommonModule {}
