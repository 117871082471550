export enum Unit {
  Unknown = 0,

  Hour = 10,
  Day = 11,
  Week = 12,
  Month = 13,

  CM = 20,
  M = 21,
  KM = 22,
  M2 = 23,
  M3 = 24,
  MC = 25,

  KG = 30,
  TONNE = 31,

  L = 40,

  KW = 50,

  Other = 999,
}
