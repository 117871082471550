import { Component, Input } from '@angular/core';

import { Subject, map } from 'rxjs';

import { CamRoutes } from '@camelot/menu';
import { CamUsersService, User } from '@camelot/user';

import { EUserRoute } from '../../../user/routing.module';
import { BaseComponent } from '../../abstract/baseComponent';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent extends BaseComponent {
  @Input()
  level: number = 1;

  @Input()
  title: string = '';

  public askClosing$ = new Subject<null>();

  get showHeader$() {
    return this.breakpoints.isMobile$;
  }

  get currentUser$() {
    return this._usersService.currentUser.get$().pipe(
      map(user => {
        return {
          profilePictureUrl: user?.picture,
          naming: null,
        };
      })
    );
  }

  constructor() {
    super();
    this._usersService.fetchCurrentUser$().subscribe();
  }

  public getUser(user: User) {
    return { profilePictureUrl: user.picture, naming: null };
  }

  public toMyProfile() {
    this.askClosing$.next(null);
    this._router.navigateByUrl(CamRoutes.getAbsoluteUrl([EUserRoute.myProfile]));
  }

  public goToEditProfile() {
    this.askClosing$.next(null);
    this._router.navigateByUrl(CamRoutes.getAbsoluteUrl([EUserRoute.myProfile, EUserRoute.editMyProfile]));
  }
}
