<div class="flex-column g-space-md" *ngLet="this.project$ | async as project">
  <div *ngIf="project">
    <div class="summary mb-space-md">
      <app-projects-summary [project]="project"></app-projects-summary>
    </div>

    <div class="cards row row-cols-1 row-cols-xl-2">
      <div>
        <app-project-dashboard-quotations [projectId]="project.id"></app-project-dashboard-quotations>
      </div>
      <div>
        <app-projects-dashboard-invoices [projectId]="project.id"></app-projects-dashboard-invoices>
      </div>
      <!-- <div>
        <app-projects-dashboard-tasks></app-projects-dashboard-tasks>
      </div> -->
    </div>
  </div>
</div>
