<cam-dashboard-card icon="contacts" *ngLet="this.menu as list" class="overlay">
  <cam-card-title>{{ 'base.home.contacts.title' | translate }}</cam-card-title>
  <cam-card-subtitle>
    {{ 'base.home.contacts.subtitle' | translate }}
  </cam-card-subtitle>

  <cam-loader [isLoading]="this.requestState.isLoading()">
    <cam-error [message]="this.requestState.getErrorMessage()" [code]="this.requestState.getErrorStatus()">
      <cam-empty [isEmpty]="list?.length === 0" text="contacts.no-contact" icon="ghost" iconSize="lg" [isLight]="false">
        <cam-list-container class="content">
          <ng-container *ngFor="let item of this.menu">
            <cam-list-element>
              <cam-list-title>
                <div>{{ item }}</div>
              </cam-list-title>
            </cam-list-element>
          </ng-container>
        </cam-list-container>
        <cam-card-cta>
          <div class="d-flex">
            <cam-button (action)="this.goToContacts()" size="small">
              {{ 'base.home.contacts.cta' | translate }}
            </cam-button>
          </div>
        </cam-card-cta>
      </cam-empty>
    </cam-error>
  </cam-loader>
</cam-dashboard-card>
