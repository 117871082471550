import { Component } from '@angular/core';

import { Observable, map } from 'rxjs';

import { UserLogoNaming } from '@camelot/ui';

import { environment } from '../../../../environments/environment';
import { BasePage } from '../../core/abstract/basePage';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomePage extends BasePage {
  get version() {
    return environment.version;
  }

  get profileName$(): Observable<{
    profilePictureUrl?: string;
    naming: UserLogoNaming | null;
  }> {
    return this._usersService.currentUser.get$().pipe(
      map(user => ({
        profilePictureUrl: user?.picture,
        naming: {
          firstName: user?.firstName || '',
          name: user?.lastName || '',
          trigram: user?.trigram || '',
        },
      }))
    );
  }
}
