import { Injectable } from '@angular/core';

import { combineLatest, filter, map, of, switchMap, tap } from 'rxjs';

import { CamBaseService, GraphEndpoint, HandleComplexRequest, HandleSimpleRequest } from '@camelot/server';
import { isNonNullable } from '@camelot/utils';

import { RefusalInfos } from './dto/mutation/RefusalInfos';
import { Quotation } from './dto/quotation/quotation';
import { QuotationRow } from './dto/quotation/row';
import { QuotationVersion } from './dto/quotation/version';
import {
  GET_ALL_VERSIONS,
  GET_QUOTATION,
  GET_QUOTATIONS_BY_PROJECT,
  GET_QUOTATION_PARENT_ROWS,
  GET_QUOTATION_ROWS,
  GET_QUOTATION_ROW_CHILDREN,
  GET_QUOTATION_VERSIONS,
  GET_VERSION,
  GET_VERSIONS_BY_PROJECT,
} from './queries';
import { QUOTATION_VERSION_REFUSED, QUOTATION_VERSION_SIGNED, READ_QUOTATION_VERSION } from './userMutations';

const graphEndpoint: GraphEndpoint = {
  clientName: 'quotationService',
  endpoint: 'quotation',
};

@Injectable({
  providedIn: 'root',
})
export class AppQuotationsService extends CamBaseService {
  public quotationsByProject = new HandleComplexRequest<Quotation[]>();

  public dashboard = new HandleComplexRequest<QuotationVersion[]>();

  public home = new HandleSimpleRequest<QuotationVersion[]>();

  public quotation = new HandleComplexRequest<Quotation>();

  public version = new HandleComplexRequest<QuotationVersion>();

  public quotationParentRows = new HandleComplexRequest<QuotationRow[]>();

  public quotationRows = new HandleComplexRequest<QuotationRow[]>();

  constructor() {
    super();

    super.registerRoutes({ graphEndpoint: graphEndpoint });
  }

  public fetchQuotationsByProject$(projectId: string) {
    return this.quotationsByProject.fetch(
      projectId,
      this._graphService.fetchQueryList<Quotation>(
        GET_QUOTATIONS_BY_PROJECT(projectId),
        'quotationsByProject',
        graphEndpoint.clientName
      )
    );
  }

  public fetchQuotation$(id: string) {
    return this.quotation.fetch(
      id,
      this._graphService
        .fetchQuery<Quotation[]>(GET_QUOTATION(id), 'quotations', graphEndpoint.clientName)
        .pipe(map(data => data[0]))
    );
  }

  public fetchQuotationRows$(versionId: string) {
    return this.quotationRows.fetch(
      versionId,
      this._graphService.fetchQuery<QuotationRow[]>(
        GET_QUOTATION_ROWS(versionId),
        'quotationRows',
        graphEndpoint.clientName
      )
    );
  }

  public fetchDashboardQuotationVersions$(id: string) {
    return this.dashboard.fetch(
      id,
      this._graphService
        .fetchPagedQueryList<QuotationVersion>(
          GET_VERSIONS_BY_PROJECT(id, 5),
          'quotationVersions',
          graphEndpoint.clientName
        )
        .pipe(map(data => data.items ?? []))
    );
  }

  public fetchAllVersions$() {
    return this.home.fetch(
      this._graphService
        .fetchPagedQueryList<QuotationVersion>(GET_ALL_VERSIONS(), 'quotationVersions', graphEndpoint.clientName)
        .pipe(map(data => data.items ?? []))
    );
  }

  public fetchQuotationVersions$(id: string) {
    return this.quotation.fetch(
      id,
      this._graphService.fetchQuery<Quotation>(
        GET_QUOTATION_VERSIONS(id),
        'quotationVersionsByQuotation',
        graphEndpoint.clientName
      )
    );
  }

  public getVersions(ids: string[]) {
    return combineLatest(ids.map(id => this.version.get$(id)));
  }

  public fetchVersions$(ids: string[]) {
    return combineLatest(
      ids.map(id =>
        this.version.fetch(
          id,
          this._graphService
            .fetchPagedQueryList<QuotationVersion>(GET_VERSION(id), 'quotationVersions', graphEndpoint.clientName)
            .pipe(
              map(list => (list.items ? list.items[0] : null)),
              filter(isNonNullable)
            )
        )
      )
    );
  }

  public fetchQuotationParentRows$(id: string) {
    return this.quotationParentRows.fetch(
      id,
      this._graphService
        .fetchQueryList<QuotationRow>(GET_QUOTATION_PARENT_ROWS(id), 'quotationParentRows', graphEndpoint.clientName)
        .pipe(
          map(list =>
            list.map(item => ({
              ...item,
              ...{ label: item.index.toString() },
            }))
          )
        )
    );
  }

  public fetchQuotationRowChildren$(id: string, parent?: QuotationRow) {
    return this.quotationParentRows.fetch(
      id,
      this._graphService
        .fetchQueryList<QuotationRow>(GET_QUOTATION_ROW_CHILDREN(id), 'quotationRowChildren', graphEndpoint.clientName)
        .pipe(
          map(list =>
            list.map(item => ({
              ...item,
              ...{ label: `${parent?.label}.${item.index}` },
            }))
          )
        )
    );
  }

  public setQuotationVersionSigned$(quotationVersionId: string) {
    return this._graphService
      .mutate<QuotationVersion>(
        QUOTATION_VERSION_SIGNED(quotationVersionId),
        'quotationVersionSigned',
        graphEndpoint.clientName
      )
      .pipe(tap(version => this.version.update(quotationVersionId, version)));
  }

  public setQuotationVersionRefused$(quotationVersionId: string, refusalInfos: RefusalInfos) {
    return this._graphService
      .mutate<QuotationVersion>(
        QUOTATION_VERSION_REFUSED(quotationVersionId, refusalInfos),
        'quotationVersionRefused',
        graphEndpoint.clientName
      )
      .pipe(tap(version => this.version.update(quotationVersionId, version)));
  }

  public isRead$(id: string) {
    return this._graphService.mutate<boolean>(
      READ_QUOTATION_VERSION(id),
      'quotationVersionRead',
      graphEndpoint.clientName
    );
  }
}
