import { GraphSchema } from '@camelot/server';

import { BillingRow } from '../common/row';

export interface QuotationRow extends BillingRow {}

export const quotationRowProps = new GraphSchema<QuotationRow>([
  'id',
  'index',
  'parent',
  'hidden',
  'rowType',
  'sourceRef',
  'description',
  'itemType',
  'quantity',
  'unit',
  'unitDescription',
  'unitSellingPrice',
  'vatPercentage',
  'exclVatTotal',
  'inclVatTotal',
  'chapterExclVatTotal',
  'chapterInclVatTotal',
  'childrenRowsCount',
  'documentIds',
]);
