import { Injectable, TemplateRef } from '@angular/core';

import { CamRoutes, Menu, MenuIcon, MenuPanel } from '@camelot/menu';

import { EBillingsRoute } from '../modules/billings/routing.module';
import { ECmsRoute } from '../modules/cms/routing.module';
import { EProjectsRoute } from '../modules/projects/routing.module';

@Injectable({
  providedIn: 'root',
})
export class AppMenuService {
  constructor() {}

  public getMainMenu() {
    const menu = [
      new MenuIcon({
        key: 'dashboard',
        label: 'menu.main.dashboard',
        link: '/',
        order: 1,
        icon: 'home',
        exact: true,
      }),
      new MenuIcon({
        key: 'projects',
        label: 'menu.main.project',
        link: CamRoutes.getAbsoluteUrl([EProjectsRoute.projects]),
        order: 2,
        icon: 'projects',
      }),
      new MenuIcon({
        key: 'invoices',
        label: 'menu.main.invoice',
        link: CamRoutes.getAbsoluteUrl([EBillingsRoute.billing, EBillingsRoute.invoices, EBillingsRoute.list]),
        order: 3,
        icon: 'facture',
      }),
      // new MenuIcon({
      //   key: 'contacts',
      //   label: 'menu.main.contact',
      //   order: 2,
      //   icon: 'contacts',
      //   disabled: true,
      // }),
      // new MenuIcon({
      //   key: 'document',
      //   label: 'menu.main.document',
      //   order: 2,
      //   icon: 'folders',
      //   disabled: true,
      // }),
    ];

    return new Menu({
      elements: menu.sort((a, b) => a.order - b.order),
      direction: 'responsive',
    });
  }

  public getUserMenu(
    accountTemplate: TemplateRef<any>,
    notificationTemplate: TemplateRef<any>,
    notificationCounterTemplate: TemplateRef<any>
  ) {
    const menu = [
      new MenuPanel({
        key: 'account',
        label: 'menu.user.account',
        order: 1,
        icon: 'contacts',
        template: accountTemplate,
        endingIcon: 'icon-back',
      }),
      new MenuPanel({
        key: 'notif',
        label: 'menu.user.notifications',
        order: 2,
        icon: 'notifications',
        template: notificationTemplate,
        options: {
          extraTemplate: notificationCounterTemplate,
        },
      }),
    ];

    return new Menu({
      elements: menu.sort((a, b) => a.order - b.order),
      direction: 'responsive',
    });
  }

  public getProjectMenu(projectId: string) {
    const menu = [
      new MenuIcon({
        key: 'summary',
        label: 'menu.project.summary',
        order: 1,
        link: CamRoutes.getAbsoluteUrl([EProjectsRoute.projects, EProjectsRoute.view, EProjectsRoute.summary], {
          id: projectId,
        }),
        defaultOpen: true,
        replaceUrl: true,
      }),
      new MenuIcon({
        key: 'quotations',
        label: 'menu.project.quotation',
        order: 2,
        link: CamRoutes.getAbsoluteUrl([EProjectsRoute.projects, EProjectsRoute.view, EProjectsRoute.quotations], {
          id: projectId,
        }),
        replaceUrl: true,
      }),
      new MenuIcon({
        key: 'invoices',
        label: 'menu.project.invoice',
        order: 4,
        link: CamRoutes.getAbsoluteUrl([EProjectsRoute.projects, EProjectsRoute.view, EProjectsRoute.invoices], {
          id: projectId,
        }),
        replaceUrl: true,
      }),
      new MenuIcon({
        key: 'documents',
        label: 'menu.project.document',
        order: 5,
        link: CamRoutes.getAbsoluteUrl([EProjectsRoute.projects, EProjectsRoute.view, EProjectsRoute.files], {
          id: projectId,
        }),
        replaceUrl: true,
      }),
      // It's on purpose
      // new MenuIcon({
      //   key: 'tasks',
      //   label: 'Taches',
      //   order: 6,
      //   link: CamRoutes.getAbsoluteUrl([EProjectsRoute.projects, EProjectsRoute.view, EProjectsRoute.tasks], {
      //     id: projectId,
      //   }),
      //   disabled: true,
      // }),
    ];

    return new Menu({
      elements: menu.sort((a, b) => a.order - b.order),
      direction: 'responsive',
    });
  }

  public getUserInfosMenu() {
    const menu = [
      new MenuIcon({
        key: 'cgu',
        label: 'menu.user-info.cgu',
        order: 2,
        style: 'dark',
        link: CamRoutes.getAbsoluteUrl([ECmsRoute.cms, ECmsRoute.legal, ECmsRoute.cgu]),
      }),
      new MenuIcon({
        key: 'infos',
        label: 'menu.user-info.cgv',
        order: 3,
        style: 'dark',
        link: CamRoutes.getAbsoluteUrl([ECmsRoute.cms, ECmsRoute.legal, ECmsRoute.cgv]),
      }),
      new MenuIcon({
        key: 'params',
        label: 'menu.user-info.disclaimer',
        order: 4,
        style: 'dark',
        link: CamRoutes.getAbsoluteUrl([ECmsRoute.cms, ECmsRoute.legal, ECmsRoute.disclaimer]),
      }),
    ];

    return new Menu({
      elements: menu.sort((a, b) => a.order - b.order),
      direction: 'vertical',
    });
  }

  public getProjectFilesMenu(projectId: string) {
    const menu = [
      new MenuIcon({
        key: 'documents',
        label: 'menu.files.document',
        order: 1,
        icon: 'doc',
        link: CamRoutes.getAbsoluteUrl([EProjectsRoute.projects, EProjectsRoute.view, EProjectsRoute.documents], {
          id: projectId,
        }),
      }),
      new MenuIcon({
        key: 'pictures',
        label: 'menu.files.picture',
        order: 2,
        icon: 'image',
        link: CamRoutes.getAbsoluteUrl([EProjectsRoute.projects, EProjectsRoute.view, EProjectsRoute.pictures], {
          id: projectId,
        }),
        disabled: true,
      }),
    ];

    return new Menu({
      elements: menu.sort((a, b) => a.order - b.order),
      direction: 'responsive',
    });
  }

  public getAllOrMineMenu(all: (data?: any) => void, mine: (data?: any) => void) {
    const menu = [
      new MenuIcon({
        key: 'all',
        label: 'Tous',
        order: 1,
        callback: all,
        defaultOpen: true,
      }),
      new MenuIcon({
        key: 'mine',
        label: 'Mes fichiers',
        order: 2,
        callback: mine,
        disabled: true,
      }),
    ];

    return new Menu({
      elements: menu.sort((a, b) => a.order - b.order),
      direction: 'responsive',
    });
  }
}
