import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { CamIconsModule } from '@camelot/icons';
import { CamNotificationModule } from '@camelot/notification';
import { CamContainerModule, CamUiModule } from '@camelot/ui';
import { CamDirectivePipeModule } from '@camelot/utils';

import { AppCoreBillingsModule } from '../billings/billings.module';
import { AppCoreProjectsModule } from '../projects/projects.module';
import { NotificationListComponent } from './list/notification-list.component';

@NgModule({
  declarations: [NotificationListComponent],
  imports: [
    AppCoreBillingsModule,
    AppCoreProjectsModule,
    CamContainerModule,
    CamDirectivePipeModule,
    CamIconsModule,
    CamNotificationModule,
    CamUiModule,
    CommonModule,
  ],
  exports: [NotificationListComponent],
})
export class AppNotificationsModule {}
