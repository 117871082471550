import { Component } from '@angular/core';
import { ParamMap, Params, convertToParamMap } from '@angular/router';

import { bufferTime, distinctUntilChanged, filter, map, reduce } from 'rxjs/operators';

import { Observable, of } from 'rxjs';

import { getPropertyTypes } from '@camelot/utils';

import { AbstractComponent } from './abstractComponent';

@Component({ template: '' })
export abstract class BasePage extends AbstractComponent {
  get pageTitle() {
    return ''; // this._navigationService.pageName ?? '';
  }

  constructor() {
    super();
  }

  protected _setPageInformation() {
    // this._navigationService.setPageInformation(info);
  }

  protected _getPathParams<T extends object>(data: T): Observable<T> {
    return this._filterParams(this._route.params, getPropertyTypes(data));
  }

  protected _getQueryParams<T extends object>(data: T): Observable<T> {
    return this._filterParams(this._route.queryParams, getPropertyTypes(data));
  }

  protected _getParentParams<T extends object>(data: T): Observable<T> {
    if (!this._route.parent) {
      return of(data);
    }
    return this._filterParams(this._route.parent?.params, getPropertyTypes(data));
  }

  private _filterParams<T>(routeParams: Observable<Params>, paramsAsked: { [K in keyof T]: string }) {
    return routeParams.pipe(
      map(params => convertToParamMap(params)),
      map<ParamMap, T>(params => this._getParamsTyped(paramsAsked, params)),
      distinctUntilChanged()
    );
  }

  private _getParamsTyped(paramsAsked: { [p: string]: string }, params: ParamMap) {
    let paramObject: any = {};

    for (let param in paramsAsked) {
      const value = params.get(param);

      if (value) paramObject[param] = paramsAsked[param] === 'number' ? Number(value) : value;
    }

    return paramObject;
  }
}
