import { Component } from '@angular/core';

import { BaseComponent } from '../../abstract/baseComponent';

@Component({
  selector: 'app-layout-second-level',
  templateUrl: './layout-second-level.component.html',
  styleUrls: ['./layout-second-level.component.scss'],
})
export class LayoutSecondLevelComponent extends BaseComponent {
  get currentRouteName() {
    return `header.navigation.${this._router.url.split('/')[1]}`;
  }

  public goBack() {
    this._location.back();
  }
}
