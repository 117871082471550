import { GraphSchema } from '@camelot/server';

import { QuotationStatus } from './quotation-status';
import { QuotationVersion } from './version';

export interface Quotation {
  id: string;
  name: string;
  projectId: string;
  tenantQuotationId: number;
  status: QuotationStatus;
  dueDate: string;
  exclVatTotal: number;
  inclVatTotal: number;
  rowsCount: number;
  rowsMigratedCount: number;
  parent: string;
  versions?: QuotationVersion[];
}

export const quotationProps = new GraphSchema<Quotation>([
  'id',
  'name',
  'projectId',
  'tenantQuotationId',
  'status',
  'dueDate',
  'exclVatTotal',
  'inclVatTotal',
  'rowsCount',
  'rowsMigratedCount',
  'versions',
]);
