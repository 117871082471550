import { Component } from '@angular/core';

import { AbstractCellComponent } from '@camelot/core';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '',
  styleUrls: ['./billing-ref-header.component.scss'],
  templateUrl: './billing-ref-header.component.html',
})
export class RefHeaderComponent extends AbstractCellComponent<unknown, string, { _children: number }> {
  get haveChildren() {
    return this._data?._children ?? 0 > 0;
  }
  get isExpanded() {
    return this.params.node.expanded ?? false;
  }
  override agInit(params: any) {
    super.agInit(params);
    this.value = params.innerRenderer(params);
  }

  toggle() {
    this.params.api.setRowNodeExpanded(this.params.node, !this.params.node.expanded);
  }

  getClasses() {
    return { ['level-' + this.params.node.level]: true };
  }
}
