import { GraphSchema } from '@camelot/server';

import { BillingItem, billingItemProps } from '../common/item';
import { InvoiceAddress } from './address';
import { PaymentStatus } from './payment-status';
import { ProgressStatements } from './progress-statements';
import { InvoiceRow } from './row';

export interface Invoice extends BillingItem {
  reference: string;
  invoiceNumber: number;

  paymentStatus: PaymentStatus;
  validationDate: string;

  invoiceAddress: InvoiceAddress;
  progressStatements: ProgressStatements[];

  contactId: string;

  structuredCommunication: string;
  beneficiaryName: string;
  beneficiaryIban: string;
  beneficiaryBic: string;
  isCreditNote: boolean;

  hiddenDetails: boolean;
}

const props: (keyof Invoice)[] = [
  'reference',
  'validationDate',
  'invoiceNumber',
  'invoiceAddress',
  'progressStatements',
  'contactId',
  'paymentStatus',
  'isCreditNote',
  'structuredCommunication',
  'beneficiaryName',
  'beneficiaryIban',
  'beneficiaryBic',
  'paymentStatus',
  'hiddenDetails',
];

export const invoiceProps = new GraphSchema<Invoice>(props.concat(billingItemProps));
