<cam-loader [isLoading]="this.requestState.isLoading()" *ngLet="this.data$ | async as list">
  <cam-error [message]="this.requestState.getErrorMessage()" [code]="this.requestState.getErrorStatus()">
    <div class="control-container mt-space-md">
      <cam-grid-tag gridId="quotations"></cam-grid-tag>
      <cam-grid-control
        gridId="quotations"
        [show]="{
          filters: false,
          treeMode: false,
          search: true,
          switchView: true,
          searchPlaceholder: 'billings.quotations.search-placeholder'
        }"
      ></cam-grid-control>
    </div>
    <cam-empty
      [isEmpty]="!list || list.length === 0"
      text="billings.no-quotations"
      [isLight]="false"
      icon="ghost"
      iconSize="xl"
    >
      <ng-container *ngIf="list && list.length > 0 && !this.requestState.isLoading()">
        <div class="mt-space-md">
          <ng-container *ngIf="this.treeMode">
            <cam-ag-grid
              gridId="quotations"
              [cardTemplate]="this.cardTemplate"
              [data]="list"
              [treeData]="this.treeData"
              [fieldsOverrides]="this.fieldsOverrides"
              [gridOptions]="{
                domLayout: 'autoHeight'
              }"
            ></cam-ag-grid>
          </ng-container>
          <ng-container *ngIf="!this.treeMode">
            <cam-ag-grid
              gridId="quotations"
              [cardTemplate]="this.cardTemplate"
              [data]="list"
              [fieldsOverrides]="this.fieldsOverrides"
              [gridOptions]="{
                domLayout: 'autoHeight'
              }"
            ></cam-ag-grid>
          </ng-container>

          <ng-template #cardTemplate let-items="items" [typedTemplate]="this.typeItem">
            <ng-container *ngIf="this.treeMode">
              <app-quotation-version-card-list
                [quotations]="this.extractQuotations(items)"
              ></app-quotation-version-card-list>
            </ng-container>
            <ng-container *ngIf="!this.treeMode">
              <div class="row row-cols-1 row-cols-md-2 row-cols-xl-3">
                <div *ngFor="let item of items" class="col mb-space-xs">
                  <app-quotation-card
                    [quotation]="item._brutContent"
                    (click)="this.navigateToQuotation(item._brutContent.id)"
                  ></app-quotation-card>
                </div>
              </div>
            </ng-container>
          </ng-template>
        </div>
      </ng-container>
    </cam-empty>
  </cam-error>
</cam-loader>
