<app-layout-second-level *ngLet="this.quotation$ | async as quotation">
  <app-layout-title *ngIf="quotation">
    <div class="name">
      <cam-new [visible]="(this.isNew$ | async) ?? false" size="xs" [isRelative]="true"></cam-new>
      {{ quotation.name }}
      <span class="version c-pointer" [matMenuTriggerFor]="menuVersion">
        V{{ this.activeVersion?.version }}
        <cam-font-icon name="arrow-small-bottom"></cam-font-icon>
      </span>
      <mat-menu #menuVersion="matMenu">
        <div class="menu-content">
          <cam-list-container>
            <ng-container *ngFor="let version of quotation.versions">
              <cam-list-element
                class="c-pointer"
                [class.active]="version.version === this.activeVersion?.version"
                (click)="this.select(version)"
              >
                <cam-list-title>
                  <div class="cam-list-title-content align-center fsz-sm">
                    <cam-new [visible]="version.isNew" [isRelative]="true" size="xs"></cam-new>
                    V{{ version.version }}
                    <span>-</span>
                    <app-quotation-status [status]="version.status" [inverse]="true"></app-quotation-status>
                  </div>
                </cam-list-title>
                <cam-list-tag>
                  {{ version.date | date : 'shortDate' }}
                </cam-list-tag>
              </cam-list-element>
            </ng-container>
          </cam-list-container>
        </div>
      </mat-menu>
    </div>
  </app-layout-title>
  <app-layout-content>
    <cam-loader [isLoading]="this.requestState.isLoading()">
      <cam-error [message]="this.requestState.getErrorMessage()" [code]="this.requestState.getErrorStatus()">
        <cam-empty [isEmpty]="!quotation">
          <app-quotations-version
            *ngIf="this.activeVersion"
            [versionId]="this.activeVersion.id"
          ></app-quotations-version>
        </cam-empty>
      </cam-error>
    </cam-loader>
  </app-layout-content>
</app-layout-second-level>
