import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnChanges, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';

import { Observable, combineLatest, map, tap } from 'rxjs';

import { AgGrid_ColDef, AgGrid_IsFullWidthRowParams, AgGrid_RowHeightParams, ITreeData } from '@camelot/core';
import { BottomSheetTemplateGenericComponent, BottomSheetTemplateGenericParams } from '@camelot/menu';
import { ObjectKeysReOrder, downloadFile } from '@camelot/utils';

import { BaseComponent } from 'src/app/modules/core/abstract/baseComponent';
import { RefHeaderComponent } from 'src/app/modules/core/billings/components/grid/billing-ref-header/billing-ref-header.component';
import { FullWidthCellCommentComponent } from 'src/app/modules/core/billings/components/grid/full-width-cell-comment/full-width-cell-comment.component';
import {
  BillingAmountCellComponent,
  BillingAmountHeaderComponent,
  BillingNameCellComponent,
  BillingNbDocumentsCellComponent,
  BillingQuantityCellComponent,
  BillingUnitCellComponent,
} from 'src/app/modules/core/billings/components/grid/grid-cell-template';
import { IPrice } from 'src/app/modules/core/billings/helpers/price';
import { BillingItem } from 'src/app/services/billings/dto/common/item';
import { BillingRow } from 'src/app/services/billings/dto/common/row';
import { RowType } from 'src/app/services/billings/dto/common/row-type';
import { Unit } from 'src/app/services/billings/dto/common/unit';

interface BillingEntity {
  name: string;
  vatTotal: IPrice;

  quantity?: number;
  unit?: Unit;
  pvUnit?: IPrice;
  nbDocuments?: number;

  _children: number;
  _brutContent: BillingRow;
}

export interface BillingDetailService {
  fetchRow$: (id: string) => Observable<any>;
  fetchRowChildren$: (id: string, parent: any) => Observable<any>;
  get$: (id: string) => Observable<BillingRow[]>;
}

@Component({
  selector: 'app-billing-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.scss'],
})
export class BillingDetailComponent extends BaseComponent implements OnChanges {
  @Input()
  item!: BillingItem;

  @Input()
  service!: BillingDetailService;

  @ViewChild('gridTemplate', { read: TemplateRef })
  gridTemplate!: TemplateRef<void>;

  @ViewChild('documentsTemplate', { read: TemplateRef })
  documentsTemplate!: TemplateRef<void>;

  public data$!: Observable<BillingEntity[]>;

  public selectedRow: BillingEntity | null = null;
  public hasDocuments = false;

  get fieldsOverrides(): { [index in keyof BillingEntity]?: AgGrid_ColDef } {
    return {
      name: {
        minWidth: this.breakpoints.isDesktop ? 300 : 200,
        flex: 1,
        cellRenderer: BillingNameCellComponent,
      },
      quantity: {
        cellRenderer: BillingQuantityCellComponent,
        cellClass: 'grid-cell-centered',
      },
      vatTotal: {
        headerComponent: BillingAmountHeaderComponent,
        cellRenderer: BillingAmountCellComponent,
        cellClass: 'grid-cell-centered',
      },
      unit: {
        cellRenderer: BillingUnitCellComponent,
        cellClass: 'grid-cell-centered',
      },
      pvUnit: {
        cellRenderer: BillingAmountCellComponent,
      },
      nbDocuments: {
        cellRenderer: BillingNbDocumentsCellComponent,
        onCellClicked: (event: { data: BillingEntity }) => {
          if (event.data.nbDocuments === 0) {
            return;
          }
          this.openDocuments(event.data);
        },
      },
    };
  }
  public treeData: ITreeData<BillingEntity> | null = this.breakpoints.isDesktop
    ? {
        serverSide: {
          isServerSideGroup: item => item._children > 0,
          getServerSideGroupKey: item => item._brutContent.index.toString(),
          service: {
            getChildren$: params => {
              if (!params.parentNode.data?._brutContent.id) {
                return this.data$;
              }
              return combineLatest({
                source: this.service.fetchRowChildren$(
                  params.parentNode.data._brutContent.id,
                  params.parentNode.data._brutContent
                ),
                isMobile: this.breakpoints.isMobile$,
              }).pipe(
                map(data => this._mapItems(data.source, data.isMobile)),
                tap(list => (this.hasDocuments = !!list.find(item => item.nbDocuments ?? 0 > 0)))
              );
            },
          },
        },
        autoGroupColumnDef: {
          name: 'Ref.',
          size: 90,
          cellClass: () => 'pl-space-sm',
          innerRenderer: params => params.data._brutContent.label,
          cellComponent: RefHeaderComponent,
        },
      }
    : null;

  constructor(private _bottomSheet: MatBottomSheet) {
    super();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.data$ = combineLatest({
      source: this.service.get$(this.item.id),
      isMobile: this.breakpoints.isMobile$,
    }).pipe(map(data => this._mapItems(data.source, data.isMobile)));
    this._fetch();
  }

  public openDetail(data: any) {
    this.downloadFile(this.item.downloadUriPDF);
    // this._bottomSheet.open<BottomSheetTemplateGenericComponent, BottomSheetTemplateGenericParams>(
    //   BottomSheetTemplateGenericComponent,
    //   {
    //     data: {
    //       template: this.gridTemplate,
    //       context: {
    //         data: data,
    //       },
    //     },
    //   }
    // );
  }

  public openDetailDocuments() {
    this._bottomSheet.open<BottomSheetTemplateGenericComponent, BottomSheetTemplateGenericParams>(
      BottomSheetTemplateGenericComponent,
      {
        data: {
          template: this.documentsTemplate,
          context: {
            data: this.item,
          },
        },
      }
    );
  }

  public downloadFile(file: string) {
    downloadFile(file);
  }

  public openDocuments(row: BillingEntity) {
    this.selectedRow = row;
  }
  public close() {
    this.selectedRow = null;
  }

  /** FullWidth */
  public readonly fullWidthCellRenderer = FullWidthCellCommentComponent;
  public getRowHeight = (params: AgGrid_RowHeightParams<BillingEntity>) => {
    if (params.data?._brutContent.rowType === RowType.Comment) {
      return 70;
    }
    return 40;
  };
  public isFullWidthRow = (params: AgGrid_IsFullWidthRowParams<BillingEntity>) => {
    return params.rowNode.data?._brutContent.rowType === RowType.Comment;
  };

  public getRowClass = (params: { data: BillingEntity }) => {
    if (!params || !params.data || !params.data._brutContent.label) {
      return '';
    }
    if (params.data._brutContent.rowType === RowType.Chapter) {
      if (params.data._brutContent.label.includes('.')) {
        return 'row-subchapter';
      }

      return 'row-chapter';
    }

    if (params.data._brutContent.rowType === RowType.Comment) {
      return 'row-comment';
    }

    return 'row-item';
  };
  private _mapItems(entities: BillingRow[], compact = false): BillingEntity[] {
    return entities
      .map(item => ({
        ...{
          name: item.description,
          vatTotal:
            item.rowType === RowType.Chapter
              ? { incl: item.chapterInclVatTotal, excl: item.chapterExclVatTotal }
              : { incl: item.inclVatTotal, excl: item.exclVatTotal },
          nbDocuments: item.documentIds?.length ?? 0,
          _children: item.childrenRowsCount,
          _brutContent: item,
        },
        ...(compact
          ? {}
          : {
              quantity: item.quantity,
              unit: item.unit,
              pvUnit: { incl: item.unitSellingPrice, excl: item.unitSellingPrice },
            }),
      }))
      .map(item =>
        ObjectKeysReOrder(item, [
          'name',
          'quantity',
          'unit',
          'pvUnit',
          'vatTotal',
          'nbDocuments',
          '_children',
          '_brutContent',
        ])
      );
  }
  private _fetch() {
    this.requestState.asked();
    this.service.fetchRow$(this.item.id).subscribe({
      complete: () => this.requestState.completed(),
      error: (error: HttpErrorResponse) => {
        this.requestState.onError(error.status, error.statusText);
      },
    });
  }
}
