<cam-loader [isLoading]="this.requestState.isLoading()">
  <cam-error [message]="this.requestState.getErrorMessage()" [code]="this.requestState.getErrorStatus()">
    <app-container-documents
      [menu]="this.switchMenu"
      id="project-document"
      [service]="this.service"
      [defaultOpenId]="this.selectionId"
    ></app-container-documents>
  </cam-error>
</cam-loader>
