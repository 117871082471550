import { Component, OnInit } from '@angular/core';

import { BasePage } from 'src/app/modules/core/abstract/basePage';
import { AppProjectsService } from 'src/app/services/projects/projects.service';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.scss'],
})
export class SummaryPage extends BasePage implements OnInit {
  public projectId: string = '';
  get project$() {
    return this._projectsService.project.get$(this.projectId);
  }

  constructor(private _projectsService: AppProjectsService) {
    super();
    this.requestState.asked();
  }

  ngOnInit() {
    this._registerSubscription(
      this._getParentParams({ id: '' }).subscribe(({ id }) => {
        this.projectId = id ?? null;
        this.requestState.completed();
      })
    );
  }
}
