import { HttpErrorResponse } from '@angular/common/http';
import { Component } from '@angular/core';

import { map } from 'rxjs';

import { CamRoutes } from '@camelot/menu';

import { EBillingsRoute } from 'src/app/modules/billings/routing.module';
import { BaseComponent } from 'src/app/modules/core/abstract/baseComponent';
import { QuotationStatus } from 'src/app/services/billings/dto/quotation/quotation-status';
import { AppQuotationsService } from 'src/app/services/billings/quotations.service';

@Component({
  selector: 'app-quotation',
  templateUrl: './quotation.component.html',
  styleUrls: ['./quotation.component.scss'],
})
export class QuotationComponent extends BaseComponent {
  get versions$() {
    return this._quotationService.home
      .get$()
      .pipe(map(versions => versions?.filter(version => version.status === QuotationStatus.Sent)));
  }

  constructor(private _quotationService: AppQuotationsService) {
    super();
    this._fetch();
  }

  public navigateToQuotation(quotationId: string, versionId?: string) {
    this._router.navigateByUrl(
      CamRoutes.getAbsoluteUrl([EBillingsRoute.billing, EBillingsRoute.quotations, EBillingsRoute.view], {
        id: quotationId,
        version: versionId ?? 'latest',
      })
    );
  }

  public setRightText(text: string) {
    return {
      text: text,
    };
  }

  private _fetch() {
    this.requestState.asked();
    this._quotationService.fetchAllVersions$().subscribe({
      complete: () => this.requestState.completed(),
      error: (error: HttpErrorResponse) => {
        this.requestState.onError(error.status, error.statusText);
      },
    });
  }
}
