<cam-loader [isLoading]="this.requestState.isLoading()" *ngLet="(this.invoices$ | async) ?? [] as invoices">
  <cam-error [message]="this.requestState.getErrorMessage()" [code]="this.requestState.getErrorStatus()">
    <div class="list" *ngIf="!this.requestState.isLoading() && invoices.length > 0">
      <div class="mb-space-md">
        <cam-text [isBold]="true" size="md"> {{ 'base.home.invoice.title' | translate }}</cam-text>
      </div>
      <div class="list-cards-container">
        <div *ngFor="let invoice of invoices">
          <app-card-payment-information
            [invoice]="invoice"
            (click)="this.navigateToInvoice(invoice.id)"
          ></app-card-payment-information>
        </div>
      </div>
    </div>
  </cam-error>
</cam-loader>
