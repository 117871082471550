<app-layout-second-level>
  <app-layout-title>
    {{ 'my-profile.pages.title.my-profile' | translate }}
  </app-layout-title>
  <app-layout-content>
    <div class="my-profile">
      <cam-user-my-profile (modifyAction)="this.goToModify()"></cam-user-my-profile>
    </div>
  </app-layout-content>
</app-layout-second-level>
