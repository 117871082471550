<div class="notification-container flex-column">
  <cam-text *ngIf="this.title">
    <div class="space-between title-container">
      <div class="flex-row align-center">
        {{ this.title | translate }}
        <div class="icon-container" *ngIf="this.iconName">
          <cam-font-icon [name]="this.iconName"></cam-font-icon>
          <cam-bullet type="notif" size="md">
            {{ this.number }}
          </cam-bullet>
        </div>
      </div>
    </div>
  </cam-text>
  <cam-notification-container
    [templates]="{ paymentStatus: this.paymentTemplate, projectStatus: this.projectTemplate }"
    [routing]="this.routing"
    [services]="this.services"
    [filters]="{
      isNew: this.isNew,
      take: this.take
    }"
    (nbChanged)="this.updateNb($event)"
  ></cam-notification-container>

  <div class="mt-space-sm px-space-sm">
    <cam-button size="small" (action)="this.goToCta()" *ngIf="this.ctaTitle">
      {{ this.ctaTitle | translate }}
    </cam-button>
  </div>
</div>

<ng-template #paymentTemplate let-data="data">
  <app-quotation-version-status [status]="data"></app-quotation-version-status>
</ng-template>
<ng-template #projectTemplate let-data="data">
  <app-project-status [status]="data"></app-project-status>
</ng-template>
