import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, Output } from '@angular/core';

import { ENotificationCode } from '@camelot/notification';

import { BaseComponent } from 'src/app/modules/core/abstract/baseComponent';
import { AppQuotationsService } from 'src/app/services/billings/quotations.service';

@Component({
  selector: 'app-quotation-version-signed',
  templateUrl: './signed.component.html',
  styleUrls: ['./signed.component.scss'],
})
export class SignedComponent extends BaseComponent {
  @Input()
  versionId!: string;

  @Output()
  closed = new EventEmitter();

  public isValid: boolean = false;

  constructor(private _quotationService: AppQuotationsService) {
    super();
  }

  public cguAcceptation(value: boolean) {
    this.isValid = value;
  }
  public cancel() {
    this.closed.emit();
  }
  public send() {
    this.requestState.asked();

    this._quotationService.setQuotationVersionSigned$(this.versionId).subscribe({
      complete: () => {
        this.requestState.completed();
        this._notificationService.addNotification(
          'billings.quotations.version.status.signed.success',
          ENotificationCode.success
        );
        this.closed.emit();
      },
      error: (error: HttpErrorResponse) => this.requestState.onError(error.status, error.statusText),
    });
  }
}
