<div class="item-container" *ngIf="this.item">
  <div class="summary">
    <app-invoice-summary [item]="this.item"></app-invoice-summary>
  </div>
  <div class="payment" *ngIf="!this.item.isCreditNote && this.item.paymentStatus !== PaymentStatus.Paid">
    <cam-title [level]="3"> {{ 'billings.payment-information-title' | translate }} </cam-title>
    <app-card-payment-information [invoice]="this.item" [onlyPayment]="true"></app-card-payment-information>
  </div>
  <div class="link g-space-md">
    <div class="description flex-column g-space-md">
      <div *ngIf="this.item.description">
        <cam-title [level]="4">{{ 'billings.description-title' | translate }}</cam-title>
        <cam-expandable-text *ngIf="this.item.description">
          <div [innerHTML]="this.item.description"></div>
        </cam-expandable-text>
        <div *ngIf="!this.item.description">
          {{ 'billings.no-description' | translate }}
        </div>
      </div>

      <div *ngIf="!this.item.isCreditNote">
        <app-invoice-quotation-list [item]="this.item"></app-invoice-quotation-list>
      </div>
    </div>
    <div class="to">
      <cam-title [level]="4">{{ 'billings.to-title' | translate }}</cam-title>
      <app-invoice-to [item]="this.item"></app-invoice-to>
    </div>
  </div>

  <div class="detail" *ngIf="!this.item.hiddenDetails && !this.item.isCreditNote">
    <div class="flex-start">
      <cam-title [level]="2">
        <span class="d-md-none">{{ 'billings.detail-title-mobile' | translate }}</span>
        <span class="d-none d-md-block">{{ 'billings.detail-title-desktop' | translate }}</span>
      </cam-title>
      <cam-grid-control
        class="d-none d-md-block"
        gridId="invoiceRow"
        [show]="{ filters: false, search: false, switchView: false }"
      ></cam-grid-control>
    </div>

    <app-billing-detail [item]="this.item" [service]="this.service"></app-billing-detail>
  </div>
  <div class="extra" *ngIf="!this.item.isCreditNote">
    <div class="download" *ngIf="this.item.downloadUriPDF">
      <div class="title">{{ 'billings.download-title' | translate }}</div>
      <div class="buttons">
        <cam-button [style]="'secondary'" (action)="this.downloadFile(this.item.downloadUriPDF)">
          <div class="d-flex">
            <cam-font-icon name="download"></cam-font-icon>
            {{ 'billings.pdf' | translate }}
          </div>
        </cam-button>
      </div>
    </div>
  </div>
</div>
