import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { CamLayoutModule, CamUiModule } from '@camelot/ui';
import { CamDirectivePipeModule } from '@camelot/utils';

import { AppLayoutModule } from '../core/layout/layout.module';
import { AppNotificationsModule } from '../core/notification/notification.module';
import { NotFoundPage } from './not-found/not-found.component';
import { NotificationsPage } from './notification/notifications.component';
import { RedirectPage } from './redirect/redirect.component';

@NgModule({
  declarations: [NotFoundPage, NotificationsPage, RedirectPage],
  imports: [
    AppLayoutModule,
    AppNotificationsModule,
    CamDirectivePipeModule,
    CamLayoutModule,
    CamUiModule,
    CommonModule,
  ],
  exports: [NotFoundPage, NotificationsPage, RedirectPage],
})
export class AppBaseModule {}
