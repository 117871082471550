<div class="flex-column g-space-lg p-space-lg">
  <div class="ta-c flex-column g-space-md">
    <cam-font-icon name="close" type="lg"></cam-font-icon>
    <cam-title [level]="3">
      {{ 'billings.quotations.version.status.refused.description' | translate }}
    </cam-title>
  </div>
  <cam-loader [isLoading]="this.requestState.isLoading()">
    <cam-error [message]="this.requestState.getErrorMessage()" [code]="this.requestState.getErrorStatus()">
      <div *ngIf="this.form">
        <cam-form [inputs]="this.form" (valid)="this.send($event)"></cam-form>
      </div>
    </cam-error>
  </cam-loader>
</div>
