import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-quotation-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss'],
})
export class LoadingComponent {
  @Input()
  progress!: { quotationRowsCount: number; quotationRowsMigratedCount: number; fullyMigrated: number };
}
