import { Routes } from '@angular/router';

import { CamRoutes } from '@camelot/menu';

import { EditMyProfilePage } from './pages/edit-profile/edit-my-profile.component';
import { MyProfilePage } from './pages/my-profile/my-profile.component';

export enum EUserRoute {
  myProfile = 'my-profile',
  editMyProfile = 'edit',
}

export class AppUserRoutingModule {
  public static loadRoutes() {
    CamRoutes.addRoute({
      key: EUserRoute.myProfile,
      url: 'my-profile',
      children: [
        {
          key: EUserRoute.editMyProfile,
          url: 'edit',
        },
      ],
    });
  }

  public static getRouting(): Routes {
    return [
      {
        path: '',
        component: MyProfilePage,
      },
      {
        path: CamRoutes.getUrl([EUserRoute.myProfile, EUserRoute.editMyProfile]),
        component: EditMyProfilePage,
      },
    ];
  }
}
