<ng-container *ngLet="(this.versions$ | async) ?? [] as versions">
  <div *ngIf="!this.requestState.isLoading() && versions.length > 0">
    <div class="mb-space-xl">
      <cam-text [isBold]="true" size="md"> {{ 'base.home.quotation.title' | translate }}</cam-text>
    </div>

    <cam-loader [isLoading]="this.requestState.isLoading()" skeleton="cardList">
      <cam-error [message]="this.requestState.getErrorMessage()" [code]="this.requestState.getErrorStatus()">
        <cam-empty
          icon="ghost"
          iconSize="xl"
          [isLight]="false"
          text="base.home.quotation.no-quotation"
          [isEmpty]="versions.length === 0 && !this.requestState.isLoading()"
        >
          <cam-swiper-light
            class="swiper"
            containerClasses="row"
            [items]="versions"
            [template]="swipeTemplate"
          ></cam-swiper-light>
        </cam-empty>
      </cam-error>
    </cam-loader>
  </div>
</ng-container>

<ng-template #swipeTemplate let-element="element">
  <app-quotation-version-card
    class="card col-11 col-md-6 col-xl-4"
    [version]="element"
    [quotationName]="element.quotation.name"
    [tenantQuotationId]="element.quotation.tenantQuotationId"
    (click)="this.navigateToQuotation(element.quotation.id, element.id)"
  ></app-quotation-version-card>
</ng-template>
