<cam-list-container>
  <ng-container *ngFor="let invoice of this.invoices">
    <cam-list-element [flexColumn]="true" (click)="this.navigateToDetail(invoice.id)">
      <cam-list-title>
        {{ invoice.reference }}
      </cam-list-title>
      <cam-list-extra-information>
        <div class="space-between align-center">
          <app-billing-amount class="amount" [amount]="this.getPrice(invoice)" display="amount"></app-billing-amount>
          <app-billings-payment-status
            [status]="invoice.paymentStatus"
            [isCreditNote]="invoice.isCreditNote"
          ></app-billings-payment-status>
        </div>
      </cam-list-extra-information>
    </cam-list-element>
  </ng-container>
</cam-list-container>
