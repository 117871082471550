import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { BaseComponent } from 'src/app/modules/core/abstract/baseComponent';
import { QuotationStatus } from 'src/app/services/billings/dto/quotation/quotation-status';
import { QuotationVersion } from 'src/app/services/billings/dto/quotation/version';

import { IPrice } from '../../../../helpers/price';

@Component({
  selector: 'app-quotation-version-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
})
export class QuotationVersionCardComponent extends BaseComponent implements OnInit {
  @Input()
  version!: QuotationVersion;

  @Input()
  quotationName: string = '';

  @Input()
  tenantQuotationId: number | null = null;

  @Input()
  shadow = true;

  @Output()
  // eslint-disable-next-line @angular-eslint/no-output-native
  click: EventEmitter<void> = new EventEmitter();

  public amount!: IPrice;

  get showDate() {
    return this.version.status === QuotationStatus.Sent || this.version.status === QuotationStatus.Expired;
  }

  get isNew() {
    return this.version.isNew;
  }
  ngOnInit() {
    this.amount = { excl: this.version.exclVatTotal, incl: this.version.inclVatTotal };
  }
}
