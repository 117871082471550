import { Injectable } from '@angular/core';

import { map } from 'rxjs';

import { CamBaseService, GraphEndpoint, HandleComplexRequest, HandleSimpleRequest } from '@camelot/server';

import { Project } from './dto/project';
import { Status } from './dto/status';
import { GET_LIGHT_PROJECTS, GET_MY_PROJECTS, GET_PROJECT_BY_ID } from './queries';

const graphEndpoint: GraphEndpoint = {
  clientName: 'projectService',
  endpoint: 'project',
};

@Injectable({
  providedIn: 'root',
})
/**
 * @deprecated Use the Camelot one or creation an héritage of it
 */
export class AppProjectsService extends CamBaseService {
  public home = new HandleSimpleRequest<Project[]>();
  public projects = new HandleSimpleRequest<Project[]>();
  public project = new HandleComplexRequest<Project>();

  constructor() {
    super();
    super.registerRoutes({ graphEndpoint: graphEndpoint });
  }

  public getProjectsLightInfo$(ids: string[]) {
    return this._graphService
      .fetchPagedQueryList<Project>(GET_LIGHT_PROJECTS(ids), 'projects', graphEndpoint.clientName)
      .pipe(map(data => data.items ?? []));
  }

  public fetchHomeProjects$() {
    return this.home.fetch(
      this._graphService
        .fetchPagedQueryList<Project>(
          GET_MY_PROJECTS({ statusList: [Status.InProgress, Status.Pending], take: 3 }),
          'projects',
          graphEndpoint.clientName
        )
        .pipe(map(data => data.items))
    );
  }

  public fetchProjects$() {
    return this.projects.fetch(
      this._graphService
        .fetchPagedQueryList<Project>(GET_MY_PROJECTS(), 'projects', graphEndpoint.clientName)
        .pipe(map(data => data.items))
    );
  }

  public fetchProject$(id: string) {
    return this.project.fetch(
      id,
      this._graphService.fetchQuery<Project>(GET_PROJECT_BY_ID(id), 'projectById', graphEndpoint.clientName)
    );
  }
}
