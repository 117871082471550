import { Component } from '@angular/core';

import { CamRoutes } from '@camelot/menu';

import { BaseComponent } from 'src/app/modules/core/abstract/baseComponent';
import { EProjectsRoute } from 'src/app/modules/projects/routing.module';

@Component({
  selector: 'app-home-tasks',
  templateUrl: './tasks.component.html',
  styleUrls: ['./tasks.component.scss'],
})
export class TasksComponent extends BaseComponent {
  public menu = [];

  public goToTasks() {
    this._router.navigateByUrl(CamRoutes.getAbsoluteUrl([EProjectsRoute.projects, EProjectsRoute.list]));
  }
}
