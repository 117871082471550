<cam-dashboard-card icon="folders" *ngLet="this.documents$ | async as list">
  <cam-card-title>{{ 'base.home.documents.title' | translate }}</cam-card-title>
  <cam-card-subtitle>
    {{ 'base.home.documents.subtitle' | translate }}
  </cam-card-subtitle>

  <cam-loader [isLoading]="this.requestState.isLoading()">
    <cam-error [message]="this.requestState.getErrorMessage()" [code]="this.requestState.getErrorStatus()">
      <cam-empty
        [isEmpty]="list?.length === 0"
        text="files.document.no-document"
        icon="ghost"
        iconSize="lg"
        [isLight]="false"
      >
        <cam-list-container class="content">
          <ng-container *ngFor="let item of list">
            <cam-list-element (click)="this.open(item)">
              <cam-list-title>
                <div class="flex-row align-center g-space-md">
                  <cam-file-image [fileName]="item.name ?? ''" size="xs"></cam-file-image>
                  <div>{{ item.name }}</div>
                </div>
              </cam-list-title>
              <cam-list-sub-title> {{ item.project?.name }} </cam-list-sub-title>
            </cam-list-element>
          </ng-container>
        </cam-list-container>
        <!-- <cam-card-cta>
          <div class="d-flex">
            <cam-button (action)="this.goToDocuments()" size="small">
              {{ 'base.home.documents.cta' | translate }}
            </cam-button>
          </div>
        </cam-card-cta> -->
      </cam-empty>
    </cam-error>
  </cam-loader>
</cam-dashboard-card>
