import { Component, Input } from '@angular/core';

import { ColorType } from '@camelot/styles';

import { QuotationStatus } from 'src/app/services/billings/dto/quotation/quotation-status';

@Component({
  selector: 'app-quotation-status',
  templateUrl: './quotation-status.component.html',
  styleUrls: ['./quotation-status.component.scss'],
})
export class QuotationStatusComponent {
  @Input()
  status!: QuotationStatus;

  @Input()
  noLabel = false;

  @Input()
  inverse: boolean = false;

  get css(): string {
    switch (this.status) {
      case QuotationStatus.Signed:
        return 'brand-primary';
      case QuotationStatus.Sent:
        return 'purple';
      case QuotationStatus.Expired:
        return 'secondary';
      case QuotationStatus.Canceled:
        return 'warning';
      case QuotationStatus.Refused:
        return 'alert';
      default:
        return 'primary';
    }
  }

  get color(): ColorType {
    switch (this.status) {
      case QuotationStatus.Signed:
        return 'success';
      case QuotationStatus.Sent:
        return 'purple';
      case QuotationStatus.Expired:
        return 'secondary';
      case QuotationStatus.Canceled:
        return 'warning';
      case QuotationStatus.Refused:
        return 'alert';
      default:
        return 'default';
    }
  }

  get label(): string {
    if (this.noLabel) {
      return '';
    }
    switch (this.status) {
      case QuotationStatus.Signed:
        return 'billings.status.signed';
      case QuotationStatus.Sent:
        return 'billings.status.sent';
      case QuotationStatus.Expired:
        return 'billings.status.expired';
      case QuotationStatus.Canceled:
        return 'billings.status.canceled';
      case QuotationStatus.Refused:
        return 'billings.status.refused';
      default:
        return 'billings.status.unknown';
    }
  }
}
