import { Routes } from '@angular/router';

import { CamRoutes } from '@camelot/menu';

import { CguPage } from './pages/cgu/cgu.component';
import { CgvPage } from './pages/cgv/cgv.component';
import { DisclaimerPage } from './pages/disclaimer/disclaimer.component';
import { LegalPage } from './pages/legal/legal.component';

export enum ECmsRoute {
  cms = 'cms',
  legal = 'legal',
  cgu = 'cgu',
  cgv = 'cgv',
  disclaimer = 'disclaimer',
}

export class AppCmsRoutingModule {
  public static loadRoutes() {
    CamRoutes.addRoute({
      key: ECmsRoute.cms,
      url: 'cms',
      children: [
        {
          key: ECmsRoute.legal,
          url: 'legal',
          children: [
            {
              key: ECmsRoute.cgu,
              url: 'cgu',
            },
            {
              key: ECmsRoute.cgv,
              url: 'cgv',
            },
            {
              key: ECmsRoute.disclaimer,
              url: 'disclaimer',
            },
          ],
        },
      ],
    });
  }

  public static getRouting(): Routes {
    return [
      {
        path: CamRoutes.getUrl([ECmsRoute.cms, ECmsRoute.legal]),
        component: LegalPage,
        children: [
          {
            path: CamRoutes.getUrl([ECmsRoute.cms, ECmsRoute.legal, ECmsRoute.cgu]),
            component: CguPage,
          },
          {
            path: CamRoutes.getUrl([ECmsRoute.cms, ECmsRoute.legal, ECmsRoute.cgv]),
            component: CgvPage,
          },
          {
            path: CamRoutes.getUrl([ECmsRoute.cms, ECmsRoute.legal, ECmsRoute.disclaimer]),
            component: DisclaimerPage,
          },
        ],
      },
    ];
  }
}
