import { Component, Input } from '@angular/core';

import { ColorType } from '@camelot/styles';

import { Status } from 'src/app/services/projects/dto/status';

@Component({
  selector: 'app-project-status',
  templateUrl: './project-status.component.html',
  styleUrls: ['./project-status.component.scss'],
})
export class ProjectStatusComponent {
  @Input()
  status!: Status;

  @Input()
  noLabel = false;

  get color(): ColorType {
    switch (this.status) {
      case Status.Done:
        return 'success';
      case Status.Pending:
        return 'purple';
      case Status.InProgress:
        return 'default';
      case Status.Cancelled:
        return 'secondary';
      default:
        return 'purple';
    }
  }

  get label(): string {
    if (this.noLabel) {
      return '';
    }
    switch (Number(this.status)) {
      case Status.Done:
        return 'projects.status.done';
      case Status.Pending:
        return 'projects.status.pending';
      case Status.InProgress:
        return 'projects.status.in-progress';
      case Status.Cancelled:
        return 'projects.status.cancelled';
      default:
        return 'projects.status.unknown';
    }
  }
}
