import { Routes } from '@angular/router';

import { CamRoutes } from '@camelot/menu';

import { ListPage } from './pages/invoices/list/list.component';
import { InvoicesViewPage } from './pages/invoices/view/view.component';
import { QuotationViewPage } from './pages/quotations/view/view.component';

export enum EBillingsRoute {
  billing = 'billings',
  quotations = 'quotations',
  invoices = 'invoices',
  view = 'view',
  list = 'list',
}

export class AppBillingsRoutingModule {
  public static loadRoutes() {
    CamRoutes.addRoute({
      key: EBillingsRoute.billing,
      url: 'billings',
      children: [
        {
          key: EBillingsRoute.quotations,
          url: 'quotations',
          children: [
            {
              key: EBillingsRoute.view,
              url: 'view/:id/:version',
            },
          ],
        },
        {
          key: EBillingsRoute.invoices,
          url: 'invoices',
          children: [
            {
              key: EBillingsRoute.view,
              url: 'view/:id',
            },
            {
              key: EBillingsRoute.list,
              url: 'list',
            },
          ],
        },
      ],
    });
  }

  public static getRouting(): Routes {
    return [
      {
        path: CamRoutes.getUrl([EBillingsRoute.billing, EBillingsRoute.quotations]),
        children: [
          {
            path: CamRoutes.getUrl([EBillingsRoute.billing, EBillingsRoute.quotations, EBillingsRoute.view]),
            component: QuotationViewPage,
          },
        ],
      },
      {
        path: CamRoutes.getUrl([EBillingsRoute.billing, EBillingsRoute.invoices]),
        children: [
          {
            path: CamRoutes.getUrl([EBillingsRoute.billing, EBillingsRoute.invoices, EBillingsRoute.list]),
            component: ListPage,
          },
          {
            path: CamRoutes.getUrl([EBillingsRoute.billing, EBillingsRoute.invoices, EBillingsRoute.view]),
            component: InvoicesViewPage,
            pathMatch: 'prefix',
          },
        ],
      },
    ];
  }
}
