import { Injectable } from '@angular/core';
import { Validators } from '@angular/forms';

import { of } from 'rxjs';

import { InputBase, InputDropdown, InputPanel, InputTextarea } from '@camelot/form-model';
import { extractEnum } from '@camelot/utils';

import { ERefusalReason } from '../dto/common/refusal-reason';
import { RefusalInfos } from '../dto/mutation/RefusalInfos';

@Injectable({
  providedIn: 'root',
})
export class AppBillingFormService {
  constructor() {}

  public getVersionRefusedForm(): InputBase<any>[] {
    return [
      new InputPanel({
        key: 'panel',
        children: [
          new InputDropdown({
            key: 'reason',
            label: 'billings.quotations.version.status.refused.reason',
            options: of(
              extractEnum(ERefusalReason)
                .map(item => ({
                  id: item.value.toString(),
                  name: `billings.refusal-reason.${item.name.toLowerCase()}`,
                }))
                .filter(item => item.id !== '0')
            ),
            validators: [Validators.required],
          }),
          new InputTextarea({
            key: 'content',
            label: 'billings.quotations.version.status.refused.comment',
          }),
        ],
      }),
    ];
  }

  public formatVersionRefusedForm(data: any): RefusalInfos {
    return {
      reason: Number(data['reason']),
      content: data['content'] ?? '',
    };
  }
}
