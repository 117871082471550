import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';

import { CamRoutes } from '@camelot/menu';
import { downloadFile } from '@camelot/utils';

import { BasePage } from 'src/app/modules/core/abstract/basePage';
import { EProjectsRoute } from 'src/app/modules/projects/routing.module';
import { AppDocumentsService } from 'src/app/services/files/documents.service';
import { DocumentDto } from 'src/app/services/files/dto/document';
import { AppProjectsService } from 'src/app/services/projects/projects.service';

@Component({
  selector: 'app-home-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.scss'],
})
export class DocumentsComponent extends BasePage {
  public documents$ = this._documentsService.dashboard.get$();

  constructor(private _documentsService: AppDocumentsService) {
    super();
    this._fetch();
  }

  public open(document: DocumentDto) {
    if (!document.projectId) {
      downloadFile(document.url);
      return;
    }
    this.goToDocuments(document);
  }

  public goToDocuments(document: DocumentDto) {
    this._router.navigate(
      [
        CamRoutes.getAbsoluteUrl([EProjectsRoute.projects, EProjectsRoute.view, EProjectsRoute.documents], {
          id: document.projectId,
        }),
      ],
      { queryParams: { selection: document.id } }
    );
  }

  private _fetch() {
    this.requestState.asked();
    this._documentsService.fetchDashboard$().subscribe({
      complete: () => this.requestState.completed(),
      error: (error: HttpErrorResponse) => {
        this.requestState.onError(error.status, error.statusText);
      },
    });
  }
}
