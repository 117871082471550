<cam-empty
  [isEmpty]="!this.quotations || this.quotations.length === 0"
  [isLight]="false"
  text="billings.quotations.list.empty"
  iconSize="lg"
>
  <cam-list-container>
    <ng-container *ngFor="let quotation of this.quotations">
      <ng-container *ngFor="let version of quotation.versions">
        <cam-list-element [flexColumn]="true" (action)="this.navigateToDetail(quotation.id, version.id)">
          <cam-list-title> {{ quotation?.name }} - {{ version.version }} </cam-list-title>
          <cam-list-extra-information>
            <div class="space-between align-center">
              <app-billing-amount
                class="amount"
                [amount]="this.getPrice(version)"
                display="amount"
              ></app-billing-amount>
              <app-quotation-status [status]="version.status"></app-quotation-status>
            </div>
          </cam-list-extra-information>
        </cam-list-element>
      </ng-container>
    </ng-container>
  </cam-list-container>
</cam-empty>
