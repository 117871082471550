import { Component, EventEmitter, Input, Output } from '@angular/core';

import { Project } from 'src/app/services/projects/dto/project';

@Component({
  selector: 'app-project-alternative-card',
  templateUrl: './project-alternative-card.component.html',
  styleUrls: ['./project-alternative-card.component.scss'],
})
export class ProjectAlternativeCardComponent {
  @Input()
  project!: Project;

  @Output()
  // eslint-disable-next-line @angular-eslint/no-output-native
  click: EventEmitter<void> = new EventEmitter();
}
