import { Component, Input } from '@angular/core';

import { CamRoutes } from '@camelot/menu';
import { BadgeType } from '@camelot/ui';

import { EBillingsRoute } from 'src/app/modules/billings/routing.module';
import { BaseComponent } from 'src/app/modules/core/abstract/baseComponent';
import { IPrice } from 'src/app/modules/core/billings/helpers/price';
import { Invoice } from 'src/app/services/billings/dto/invoice/invoice';
import { PaymentStatus } from 'src/app/services/billings/dto/invoice/payment-status';

@Component({
  selector: 'app-invoices-list',
  templateUrl: './invoices-list.component.html',
  styleUrls: ['./invoices-list.component.scss'],
})
export class InvoicesListComponent extends BaseComponent {
  @Input()
  invoices!: Invoice[];

  public getColor(status: PaymentStatus): BadgeType {
    switch (status) {
      case PaymentStatus.Paid:
        return 'primary';
      case PaymentStatus.PartiallyPaid:
      case PaymentStatus.NotPaid:
        return 'warning';
      case PaymentStatus.Late:
        return 'warning';
      default:
        return 'info';
    }
  }

  public getLabel(status: PaymentStatus): string {
    switch (status) {
      case PaymentStatus.Late:
        return 'billings.payment-status.late';
      case PaymentStatus.NotPaid:
        return 'billings.payment-status.notpaid';
      case PaymentStatus.Paid:
        return 'billings.payment-status.paid';
      case PaymentStatus.PartiallyPaid:
        return 'billings.payment-status.partiallypaid';
      default:
        return 'billings.payment-status.unknown';
    }
  }

  public getPrice(invoice: Invoice): IPrice {
    return {
      excl: invoice.exclVatTotal,
      incl: invoice.inclVatTotal,
    };
  }

  public navigateToDetail(id: string) {
    this._router.navigateByUrl(
      CamRoutes.getAbsoluteUrl([EBillingsRoute.billing, EBillingsRoute.invoices, EBillingsRoute.view], { id: id })
    );
  }
}
