import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { CamMenuModule } from '@camelot/menu';
import { CamNotificationModule } from '@camelot/notification';
import { CamUiModule } from '@camelot/ui';
import { CamUserModule } from '@camelot/user';
import { CamDirectivePipeModule } from '@camelot/utils';

import { AppNotificationsModule } from '../notification/notification.module';
import { MainMenuComponent } from './main-menu/main-menu.component';

@NgModule({
  declarations: [MainMenuComponent],
  imports: [
    AppNotificationsModule,
    CamDirectivePipeModule,
    CamMenuModule,
    CamNotificationModule,
    CamUiModule,
    CamUserModule,
    CommonModule,
  ],
  exports: [MainMenuComponent],
})
export class AppMenuModule {}
