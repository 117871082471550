<ng-container *ngLet="(this.inProgressProjects$ | async) ?? [] as projects">
  <div class="mb-space-md project-header align-center">
    <cam-text [isBold]="true" size="md"> {{ 'base.home.project.title' | translate }}</cam-text>
    <cam-button [style]="'secondary'" size="small" (action)="this.goToProjects()">
      {{ 'base.home.project.cta' | translate }}
    </cam-button>
  </div>

  <cam-loader [isLoading]="this.requestState.isLoading()" skeleton="cardList">
    <cam-error [message]="this.requestState.getErrorMessage()" [code]="this.requestState.getErrorStatus()">
      <cam-empty
        icon="ghost"
        iconSize="xl"
        [isLight]="false"
        [text]="'base.home.project.no-project'"
        [isEmpty]="projects.length === 0"
      >
        <cam-swiper-light
          class="swiper"
          containerClasses="row"
          [items]="projects"
          [template]="swipeTemplate"
        ></cam-swiper-light>
      </cam-empty>
    </cam-error>
  </cam-loader>
</ng-container>

<ng-template #swipeTemplate let-element="element">
  <app-project-card
    class="card-force-mobile card col-11 col-md-6 col-lg-4"
    [project]="element"
    (click)="this.navigateToProject(element.id)"
  ></app-project-card>
</ng-template>
