import { GraphSchema } from '@camelot/server';

export interface InvoiceAddress {
  id: number;
  country: string;
  city: string;
  postCode: string;
  street: string;
}

export const invoiceAddressProps = new GraphSchema<InvoiceAddress>(['id', 'country', 'city', 'postCode', 'street']);
