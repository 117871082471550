import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { CamIconsModule } from '@camelot/icons';
import { CamMenuModule } from '@camelot/menu';
import { CamCardModule, CamContainerModule, CamLayoutModule, CamListModule, CamUiModule } from '@camelot/ui';
import { CamDirectivePipeModule } from '@camelot/utils';

import { AppDocumentsService } from 'src/app/services/files/documents.service';
import { AppProjectsService } from 'src/app/services/projects/projects.service';

import { AppCoreBillingsModule } from '../../core/billings/billings.module';
import { AppLayoutModule } from '../../core/layout/layout.module';
import { AppMenuModule } from '../../core/menu/menu.module';
import { AppCoreProjectsModule } from '../../core/projects/projects.module';
import { ContactsComponent } from './contacts/contacts.component';
import { DocumentsComponent } from './documents/documents.component';
import { HomePage } from './home.component';
import { InvoiceComponent } from './invoice/invoice.component';
import { ProjectComponent } from './project/project.component';
import { QuotationComponent } from './quotation/quotation.component';
import { TasksComponent } from './tasks/tasks.component';

@NgModule({
  declarations: [
    ContactsComponent,
    DocumentsComponent,
    HomePage,
    ProjectComponent,
    QuotationComponent,
    TasksComponent,
    InvoiceComponent,
  ],
  imports: [
    AppCoreProjectsModule,
    AppLayoutModule,
    AppMenuModule,
    CamCardModule,
    CamContainerModule,
    CamDirectivePipeModule,
    CamIconsModule,
    CamLayoutModule,
    CamListModule,
    CamMenuModule,
    CamUiModule,
    CommonModule,
    AppCoreBillingsModule,
    AppCoreProjectsModule,
  ],
  exports: [HomePage],
  providers: [AppDocumentsService, AppProjectsService],
})
export class AppHomeModule {}
