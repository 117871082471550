import { Injectable, OnDestroy, inject } from '@angular/core';

import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import {
  AccountInfo,
  AuthenticationResult,
  EventMessage,
  EventType,
  IdTokenClaims,
  PopupRequest,
  PromptValue,
  RedirectRequest,
  SsoSilentRequest,
} from '@azure/msal-browser';
import {
  BehaviorSubject,
  Observable,
  Subject,
  catchError,
  filter,
  from,
  map,
  of,
  switchMap,
  takeUntil,
  throwError,
} from 'rxjs';

import { MappingApiType } from '@camelot/server';
import { CamAuthService, CamUserService, Permissions, UserProfile } from '@camelot/user';
import { trigram } from '@camelot/utils';

import { AppRedirectService } from 'src/app/services/redirect.service';
import { environment } from 'src/environments/environment';

const apiRoutes: MappingApiType = {
  GetUserProfile: {
    type: 'GET',
    url: '{ApiUrl}/UserProfile',
  },
};

type IdTokenClaimsWithPolicyId = IdTokenClaims & {
  acr?: string;
  tfp?: string;
};

@Injectable({
  providedIn: 'root',
})
export class AzureAuthService extends CamAuthService<any> implements OnDestroy {
  private readonly _destroying$ = new Subject<void>();

  private authService = inject(MsalService);
  private msalBroadcastService = inject(MsalBroadcastService);
  private redirectService = inject(AppRedirectService);

  override get userProfile$(): BehaviorSubject<UserProfile | null> {
    return this.userService.userProfile$;
  }

  override get trigram(): string | null | undefined {
    return trigram(this.userProfile$.value?.trigram);
  }

  override get firstLetter(): string | null {
    const name = this.userProfile$.value?.trigram;
    if (!name) {
      return '-';
    }
    return name[0].toUpperCase();
  }

  public userService = inject(CamUserService);
  constructor() {
    super(apiRoutes);

    this.user$.pipe(takeUntil(this._destroying$)).subscribe(userProfile => {
      const currentUrl = window.location.href;
      const urlPath = new URL(currentUrl).pathname;
      if (userProfile || urlPath.includes('login')) {
        return;
      }
      this.redirectService.setUrlPath(urlPath);
    });

    this.authService
      .initialize()
      .pipe(
        switchMap(() => {
          return this.acquireTokenSilently([]);
        }),
        map(result => {
          this.user$.next(result.account);
          Permissions.setAuthenticated(true);
        }),
        catchError(err => {
          Permissions.setAuthenticated(false);
          return of();
        })
      )
      .subscribe();

    this.msalBroadcastService.msalSubject$
      .pipe(
        filter(
          (msg: EventMessage) =>
            msg.eventType === EventType.LOGIN_SUCCESS ||
            msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS ||
            msg.eventType === EventType.SSO_SILENT_SUCCESS
        ),
        takeUntil(this._destroying$)
      )
      .subscribe((result: EventMessage) => {
        let payload = result.payload as AuthenticationResult;
        let idtoken = payload.idTokenClaims as IdTokenClaimsWithPolicyId;

        if (
          idtoken.acr === environment.AADB2C.b2cPolicies.names.signUpSignIn ||
          idtoken.tfp === environment.AADB2C.b2cPolicies.names.signUpSignIn
        ) {
          this.authService.instance.setActiveAccount(payload.account);
        }

        /**
         * For the purpose of setting an active account for UI update, we want to consider only the auth response resulting
         * from SUSI flow. "acr" claim in the id token tells us the policy (NOTE: newer policies may use the "tfp" claim instead).
         * To learn more about B2C tokens, visit https://docs.microsoft.com/en-us/azure/active-directory-b2c/tokens-overview
         */
        if (
          idtoken.acr === environment.AADB2C.b2cPolicies.names.editProfile ||
          idtoken.tfp === environment.AADB2C.b2cPolicies.names.editProfile
        ) {
          // retrieve the account from initial sing-in to the app
          const originalSignInAccount = this.authService.instance
            .getAllAccounts()
            .find(
              (account: AccountInfo) =>
                account.idTokenClaims?.oid === idtoken.oid &&
                account.idTokenClaims?.sub === idtoken.sub &&
                ((account.idTokenClaims as IdTokenClaimsWithPolicyId).acr ===
                  environment.AADB2C.b2cPolicies.names.signUpSignIn ||
                  (account.idTokenClaims as IdTokenClaimsWithPolicyId).tfp ===
                    environment.AADB2C.b2cPolicies.names.signUpSignIn)
            );

          let signUpSignInFlowRequest: SsoSilentRequest = {
            authority: environment.AADB2C.b2cPolicies.authorities.signUpSignIn.authority,
            account: originalSignInAccount,
          };

          // silently login again with the signUpSignIn policy
          this.authService.ssoSilent(signUpSignInFlowRequest);
        }

        /**
         * Below we are checking if the user is returning from the reset password flow.
         * If so, we will ask the user to reauthenticate with their new password.
         * If you do not want this behavior and prefer your users to stay signed in instead,
         * you can replace the code below with the same pattern used for handling the return from
         * profile edit flow (see above ln. 74-92).
         */
        if (
          idtoken.acr === environment.AADB2C.b2cPolicies.names.resetPassword ||
          idtoken.tfp === environment.AADB2C.b2cPolicies.names.resetPassword
        ) {
          let signUpSignInFlowRequest: RedirectRequest | PopupRequest = {
            authority: environment.AADB2C.b2cPolicies.authorities.signUpSignIn.authority,
            scopes: [...environment.AADB2C.apiConfig.scopes],
            prompt: PromptValue.LOGIN, // force user to reauthenticate with their new password
          };

          this.login();
        }

        return result;
      });

    this.msalBroadcastService.msalSubject$
      .pipe(
        filter(
          (msg: EventMessage) =>
            msg.eventType === EventType.LOGIN_FAILURE || msg.eventType === EventType.ACQUIRE_TOKEN_FAILURE
        ),
        takeUntil(this._destroying$)
      )
      .subscribe((result: EventMessage) => {
        // Check for forgot password error
        // Learn more about AAD error codes at https://docs.microsoft.com/en-us/azure/active-directory/develop/reference-aadsts-error-codes
        if (result.error && result.error.message.indexOf('AADB2C90118') > -1) {
          let resetPasswordFlowRequest: RedirectRequest | PopupRequest = {
            authority: environment.AADB2C.b2cPolicies.authorities.resetPassword.authority,
            scopes: [],
          };

          this.login();
        }
      });
  }

  override ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }

  override fetchUserProfile(): Observable<UserProfile> {
    return this.userService.fetchUserProfile();
  }

  override load(): void {}

  checkAndSetActiveAccount() {
    /**
     * If no active account set but there are accounts signed in, sets first account to active account
     * To use active account set here, subscribe to inProgress$ first in your component
     * Note: Basic usage demonstrated. Your app may require more complicated account selection logic
     */
    let activeAccount = this.authService.instance.getActiveAccount();

    if (!activeAccount && this.authService.instance.getAllAccounts().length > 0) {
      let accounts = this.authService.instance.getAllAccounts();
      this.authService.instance.setActiveAccount(accounts[0]);
    }
  }

  async login() {
    await this.authService.instance.initialize();
    await this.authService.instance.handleRedirectPromise();
    this.authService.loginRedirect();
  }

  async logout() {
    return this.authService.instance
      .handleRedirectPromise()
      .then(authResult => {
        const account = this.authService.instance.getActiveAccount();
        this.authService.instance
          .logoutRedirect({
            account: account,
            postLogoutRedirectUri: '/',
          })
          .then<null>(result => {
            Permissions.setAuthenticated(false);
            return null;
          });
      })
      .catch(err => {
        console.log(err);
      })
      .then<null>(result => {
        Permissions.setAuthenticated(false);
        return null;
      });
  }

  getTokenFromCache() {
    return this.authService.initialize().pipe(
      switchMap(() => {
        return this.acquireTokenSilently([]);
      })
    );
  }

  acquireTokenSilently(scopes: string[]): Observable<AuthenticationResult> {
    const account = this.authService.instance.getAllAccounts()[0];
    // if (!account) {
    //   throwError(() => new Error('No accounts detected'));
    // }

    return from(
      this.authService.acquireTokenSilent({
        account: account,
        scopes: scopes,
      })
    ).pipe(
      catchError(error => {
        return this.acquireTokenInteractive(scopes);
      })
    );
  }

  acquireTokenInteractive(scopes: string[]): Observable<AuthenticationResult> {
    const request: PopupRequest = {
      scopes: scopes,
      prompt: 'select_account',
    };

    return from(this.authService.instance.handleRedirectPromise()).pipe(
      switchMap(authResult => {
        if (authResult) {
          return of(authResult);
        } else {
          return throwError(() => new Error('Interactive login failed'));
        }
      })
    );
  }
}
