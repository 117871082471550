import { GraphSchema } from '@camelot/server';

import { Address } from './address';
import { Status } from './status';
import { Tenant } from './tenant';

export interface Project {
  id: string;
  name: string;
  status: Status;
  address: Address;
  tenantInformation: Tenant;
}

export const projectProps = new GraphSchema<Project>(['id', 'name', 'status', 'address', 'tenantInformation']);
