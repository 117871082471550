import { HttpErrorResponse } from '@angular/common/http';
import { Component } from '@angular/core';

import { map } from 'rxjs';

import { AbstractCellComponent, AgGrid_ColDef, CamGridData, CamGridDataService, ITreeData } from '@camelot/core';
import { CamRoutes } from '@camelot/menu';

import { EBillingsRoute } from 'src/app/modules/billings/routing.module';
import { BasePage } from 'src/app/modules/core/abstract/basePage';
import { RefHeaderComponent } from 'src/app/modules/core/billings/components/grid/billing-ref-header/billing-ref-header.component';
import {
  BillingAmountCellComponent,
  BillingAmountHeaderComponent,
  BillingDueDateCellComponent,
} from 'src/app/modules/core/billings/components/grid/grid-cell-template';
import { Quotation } from 'src/app/services/billings/dto/quotation/quotation';
import { QuotationStatus } from 'src/app/services/billings/dto/quotation/quotation-status';
import { QuotationVersion } from 'src/app/services/billings/dto/quotation/version';
import { AppQuotationsService } from 'src/app/services/billings/quotations.service';

interface Row {
  id: string;
  name: string;
  status: QuotationStatus;
  dueDate: string;
  vatTotal: { excl: number; incl: number };
  _children: number;
  _parent: string[];
  _brutContent: Quotation | QuotationVersion;
}
@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '',
  templateUrl: './quotations.component.html',
  styleUrls: ['./quotations.component.scss'],
})
export class QuotationsPage extends BasePage {
  public projectId: string = '';

  public readonly typeItem!: { items: { _brutContent: Quotation; _children: number }[] };

  protected _grid: CamGridData<Row>;

  get treeMode() {
    return true;
  }
  get fieldsOverrides(): { [index: string]: AgGrid_ColDef } {
    return {
      name: { minWidth: 200, flex: 1, cellRenderer: NameCellComponent },
      status: { cellRenderer: QuotationStatusCellComponent },
      vatTotal: {
        cellRenderer: BillingAmountCellComponent,
        headerComponent: BillingAmountHeaderComponent,
      },
      dueDate: { cellRenderer: BillingDueDateCellComponent },
    };
  }

  get data$() {
    return this._quotationService.quotationsByProject.get$(this.projectId).pipe(
      map(list => {
        if (!this.treeMode) {
          return list?.map(quotation => this._mapItem(quotation));
        }

        return list?.reduce<Row[]>((acc, quotation) => [...acc, ...this._mapItemWithChildren(quotation)], []);
      })
    );
  }

  public treeData: ITreeData<Row> = {
    clientSide: {
      getDataPath: data => {
        return data._parent;
      },
    },
    autoGroupColumnDef: {
      name: 'Ref.',
      size: 70,
      cellClass: () => 'grid-cell-right',
      innerRenderer: params => '',
      cellComponent: RefHeaderComponent,
    },
  };

  constructor(private _quotationService: AppQuotationsService, private _dataService: CamGridDataService<Row>) {
    super();
    this._grid = this._dataService.get('quotations', true);

    //TODO Check for another solution
    if (this._route.parent) {
      this._registerSubscription(
        this._route.parent.params.subscribe(parentParams => {
          this.projectId = parentParams['id'];

          this._fetch();

          this._registerSubscription(
            this._grid.selectedData$.subscribe(data => {
              if (data.length == 1) {
                const row = data[0];
                if (row._children > 0 || !this.treeMode) {
                  this.navigateToQuotation(row.id);
                  return;
                }
                const parentId = row._parent.filter(id => id !== row.id)[0] ?? '';
                this.navigateToQuotation(parentId, row.id);
              }
            })
          );
        })
      );
    }
  }

  public extractQuotations(items: { _brutContent: Quotation; _children: number }[]) {
    return items.map(item => item._brutContent);
  }

  public navigateToQuotation(quotationId: string, versionId?: string) {
    this._router.navigateByUrl(
      CamRoutes.getAbsoluteUrl([EBillingsRoute.billing, EBillingsRoute.quotations, EBillingsRoute.view], {
        id: quotationId,
        version: versionId ?? 'latest',
      })
    );
  }

  private _fetch() {
    this.requestState.asked();
    this._quotationService.fetchQuotationsByProject$(this.projectId).subscribe({
      complete: () => this.requestState.completed(),
      error: (error: HttpErrorResponse) => {
        this.requestState.onError(error.status, error.statusText);
      },
    });
  }

  private _mapItemWithChildren(quotation: Quotation): Row[] {
    return [quotation, ...(quotation.versions?.map(v => ({ ...v, ...{ name: 'V' + v.version } })) ?? [])].map(item =>
      this._mapItem(item, quotation)
    );
  }

  private _mapItem = (item: Quotation | (QuotationVersion & { name: string }), parent?: Quotation): Row => ({
    id: item.id,
    name: item.name,
    status: item.status,
    dueDate: item.dueDate,
    vatTotal: { excl: item.exclVatTotal, incl: item.inclVatTotal },
    _parent: parent && item.id !== parent.id ? [parent.id, item.id] : [item.id],
    _children: item.id === parent?.id ? 1 : 0,
    _brutContent: item,
  });
}

/**
 * override cell template
 */
@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '',
  template: `<div class="align-center" style="height: 100%">
    <app-quotation-status style="line-height: normal" [status]="this.status"></app-quotation-status>
  </div>`,
})
export class QuotationStatusCellComponent extends AbstractCellComponent<Quotation, QuotationStatus> {
  get status() {
    return this.value ?? QuotationStatus.Unknown;
  }
}

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '',
  template: `<div class="d-flex align-center g-space-sm">
    <cam-new [visible]="this.isNew" [isRelative]="true" size="xs"></cam-new>
    {{ this.value }}
  </div> `,
})
export class NameCellComponent extends AbstractCellComponent<Quotation, string> {
  get isNew() {
    return !!this._item?.versions?.find(version => version.isNew);
  }
}
