<app-layout-first-level>
  <app-layout-title>
    {{ 'cms.pages.legal.title' | translate }}
  </app-layout-title>
  <app-layout-content>
    <cam-toggle-navigation *ngIf="this.menu" [menu]="this.menu" container="tab"></cam-toggle-navigation>
    <div class="mt-space-md">
      <router-outlet></router-outlet>
    </div>
  </app-layout-content>
</app-layout-first-level>
