<cam-card [noContent]="true" [fullHeight]="true" [isNew]="this.isNew" (click)="this.click.emit()">
  <cam-card-header>
    <cam-card-tag>
      <div>{{ 'billings.quotations.reference' | translate }}{{ this.quotation.tenantQuotationId }}</div>
    </cam-card-tag>
    <cam-card-title>
      <div class="space-between">
        <cam-title [level]="4" [isBold]="true">
          {{ this.quotation.name }}
        </cam-title>
      </div>
    </cam-card-title>
  </cam-card-header>
  <cam-card-cta>
    <div class="space-between align-end">
      <div class="flex-row g-space-sm align-center">
        <app-quotation-status [status]="this.quotation.status"></app-quotation-status>
        <div class="date" *ngIf="this.showDate">
          {{ this.quotation.dueDate | date }}
        </div>
      </div>
      <div class="cta">
        <div class="label">
          <app-billing-amount [amount]="this.amount" display="label"></app-billing-amount>
        </div>
        <div class="amount">
          <app-billing-amount [amount]="this.amount" display="amount"></app-billing-amount>
        </div>
      </div>
    </div>
  </cam-card-cta>
</cam-card>
