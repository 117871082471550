import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';

import { of } from 'rxjs';

import { BasePage } from 'src/app/modules/core/abstract/basePage';
import { AppInvoicesService } from 'src/app/services/billings/invoices.service';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '',
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.scss'],
})
export class InvoicesViewPage extends BasePage implements OnInit {
  public id: string = '';

  get invoice$() {
    return this._invoicesService.invoice.get$(this.id);
  }

  constructor(private _invoicesService: AppInvoicesService) {
    super();
  }

  ngOnInit() {
    this._registerSubscription(this._getPathParams({ id: '' }).subscribe(({ id }) => (this.id = id ?? null)));
    this._fetch();
  }

  private _fetch() {
    this.requestState.asked();
    this._invoicesService.fetchInvoice$(this.id).subscribe({
      complete: () => {
        this.requestState.completed();
      },
      error: (error: HttpErrorResponse) => {
        this.requestState.onError(error.status, error.statusText);
      },
    });
  }
}
