import { Component } from '@angular/core';

import { BasePage } from 'src/app/modules/core/abstract/basePage';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
})
export class ListPage extends BasePage {}
