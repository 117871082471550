<div *ngIf="this.projectId">
  <div class="row row-cols-1 row-cols-xl-2" *ngIf="this.breakpoints.isDesktop$ | async">
    <app-project-dashboard-documents [projectId]="this.projectId"></app-project-dashboard-documents>
    <app-project-dashboard-picutres [projectId]="this.projectId"></app-project-dashboard-picutres>
  </div>
  <div *ngIf="this.breakpoints.isMobile$ | async">
    <div class="space-between align-center m-space-md" (click)="this.navigateToDocs()">
      <div class="flex-row g-space-md">
        <cam-font-icon name="doc"></cam-font-icon>
        <cam-title [level]="3">{{ 'projects.dashboard.documents.title' | translate }}</cam-title>
      </div>
      <div>
        <cam-font-icon name="arrow-big-right"></cam-font-icon>
      </div>
    </div>
    <div class="sep"></div>
    <div class="space-between align-center m-space-md overlay" (click)="this.navigateToPics()">
      <div class="flex-row g-space-md">
        <cam-font-icon name="image"></cam-font-icon>
        <cam-title [level]="3"> {{ 'projects.dashboard.pictures.title' | translate }}</cam-title>
      </div>
      <div>
        <cam-font-icon name="arrow-big-right"></cam-font-icon>
      </div>
    </div>
  </div>
</div>
