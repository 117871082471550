<div class="quotations-lists">
  <div class="in-progress-container" *ngIf="this.inProgressQuotations">
    <cam-title [level]="2" *ngIf="this.inProgressQuotations.length > 1">
      {{ 'billings.quotations.list.in-progress-title' | translate }}
    </cam-title>
    <div class="list-cards-container" *ngFor="let quotation of this.inProgressQuotations">
      <cam-text [isBold]="true" size="md"> {{ quotation.name }}</cam-text>
      <div class="list">
        <div *ngFor="let version of quotation.versions">
          <app-quotation-version-card
            [version]="version"
            [quotationName]="quotation.name"
            [tenantQuotationId]="quotation.tenantQuotationId"
            (click)="this.navigateToQuotation(quotation.id, version.id)"
          ></app-quotation-version-card>
        </div>
      </div>
    </div>
  </div>
  <div class="signed-versions-container" *ngIf="this.signedQuotations && this.signedQuotations.length > 0">
    <cam-title [level]="2">{{ 'billings.quotations.list.signed-versions-title' | translate }}</cam-title>
    <app-quotations-list [quotations]="this.signedQuotations"></app-quotations-list>
  </div>
  <div class="expired-versions-container" *ngIf="this.expiredQuotations && this.expiredQuotations.length > 0">
    <cam-title [level]="2">{{ 'billings.quotations.list.expired-versions-title' | translate }}</cam-title>
    <app-quotations-list [quotations]="this.expiredQuotations"></app-quotations-list>
  </div>
</div>
