<ng-container *ngIf="!this.inverse">
  <ng-container *ngIf="!this.noLabel">
    <cam-label [type]="this.color" size="sm"> {{ this.label | translate }}</cam-label>
  </ng-container>
  <ng-container *ngIf="this.noLabel">
    <cam-bullet [type]="this.color"></cam-bullet>
  </ng-container>
</ng-container>
<ng-container *ngIf="this.inverse">
  <span ngClass="text-color-text-{{ this.css }}">{{ this.label | translate }}</span>
</ng-container>
