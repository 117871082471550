import { Component, Input } from '@angular/core';

import { ColorType } from '@camelot/styles';

import { ItemType } from 'src/app/services/billings/dto/common/item-type';

import { BaseComponent } from '../../../abstract/baseComponent';

@Component({
  selector: 'app-billings-item-type-status',
  templateUrl: './item-type-status.component.html',
  styleUrls: ['./item-type-status.component.scss'],
})
export class BillingsItemTypeStatusComponent extends BaseComponent {
  @Input()
  public type!: ItemType;

  get show() {
    return this.type !== ItemType.Normal && this.type !== ItemType.Unknown;
  }
  public getType(): ColorType {
    switch (this.type) {
      case ItemType.Reduction:
        return 'success';
      case ItemType.Options:
        return 'warning';
      default:
        return 'default';
    }
  }

  public getTranslate() {
    return `billings.types.${ItemType[this.type ?? ItemType.Unknown].toLocaleLowerCase()}`;
  }
}
