import { Component } from '@angular/core';

import { AbstractCellComponent } from '@camelot/core';

import { BillingRow } from 'src/app/services/billings/dto/common/row';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '',
  styleUrls: ['./full-width-cell-comment.component.scss'],
  templateUrl: './full-width-cell-comment.component.html',
})
export class FullWidthCellCommentComponent extends AbstractCellComponent<BillingRow, string> {}
