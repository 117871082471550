import { Component, Input } from '@angular/core';

import { CamRoutes } from '@camelot/menu';

import { EBillingsRoute } from 'src/app/modules/billings/routing.module';
import { BaseComponent } from 'src/app/modules/core/abstract/baseComponent';
import { Quotation } from 'src/app/services/billings/dto/quotation/quotation';
import { QuotationStatus } from 'src/app/services/billings/dto/quotation/quotation-status';
import { QuotationVersion } from 'src/app/services/billings/dto/quotation/version';

@Component({
  selector: 'app-quotation-version-card-list',
  templateUrl: './quotation-version-card-list.component.html',
  styleUrls: ['./quotation-version-card-list.component.scss'],
})
export class QuotationVersionCardListComponent extends BaseComponent {
  @Input()
  quotations!: Quotation[] | null;

  get inProgressQuotations() {
    if (this.quotations) {
      return this.applyStatusFilter(this.quotations, [QuotationStatus.Sent]);
    }
    return;
  }

  get signedQuotations() {
    if (this.quotations) {
      return this.applyStatusFilter(this.quotations, [QuotationStatus.Signed]);
    }
    return;
  }

  get expiredQuotations() {
    if (this.quotations) {
      return this.applyStatusFilter(this.quotations, [
        QuotationStatus.Refused,
        QuotationStatus.Canceled,
        QuotationStatus.Expired,
      ]);
    }
    return;
  }

  public navigateToQuotation(quotationId: string, versionId?: string) {
    this._router.navigateByUrl(
      CamRoutes.getAbsoluteUrl([EBillingsRoute.billing, EBillingsRoute.quotations, EBillingsRoute.view], {
        id: quotationId,
        version: versionId ?? 'latest',
      })
    );
  }

  public hasMultipleGroups(groupedByQuotationName: { [key: string]: QuotationVersion[] }): boolean {
    return Object.keys(groupedByQuotationName).length > 1;
  }

  private applyStatusFilter(quotations: Quotation[], statusList: QuotationStatus[]) {
    return quotations
      ?.filter(quotation => quotation.versions && quotation.versions.length > 0)
      .map(quotation => ({
        ...quotation,
        ...{ versions: quotation.versions?.filter(version => statusList.includes(version.status)) },
      }))
      .filter(quotation => quotation.versions && quotation.versions.length > 0);
  }
}
