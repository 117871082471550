<app-layout-second-level *ngLet="this.invoice$ | async as invoice">
  <app-layout-title>
    <div class="name" *ngIf="invoice">{{ invoice.reference }}</div>
  </app-layout-title>
  <app-layout-content>
    <cam-loader [isLoading]="this.requestState.isLoading()">
      <cam-error [message]="this.requestState.getErrorMessage()" [code]="this.requestState.getErrorStatus()">
        <cam-empty [isEmpty]="!invoice">
          <app-invoice-item *ngIf="invoice" [item]="invoice"></app-invoice-item>
        </cam-empty>
      </cam-error>
    </cam-loader>
  </app-layout-content>
</app-layout-second-level>
