import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { MsalRedirectComponent } from '@azure/msal-angular';

import { CamMainRoute, CamRoutes } from '@camelot/menu';
import { AuthGuard, LoginRedirectComponent } from '@camelot/user';

import { environment } from 'src/environments/environment';

import { UnauthenticatedAzureGuard } from './modules/azure/guards/unauthenticated-azure-guard';
import { HomeGuard } from './modules/base/home/guards/home-guard';
import { HomePage } from './modules/base/home/home.component';
import { NotFoundPage } from './modules/base/not-found/not-found.component';
import { NotificationsPage } from './modules/base/notification/notifications.component';
import { RedirectPage } from './modules/base/redirect/redirect.component';
import { AppBillingsRoutingModule, EBillingsRoute } from './modules/billings/routing.module';
import { AppCmsRoutingModule, ECmsRoute } from './modules/cms/routing.module';
import { AppProjectsRoutingModule, EProjectsRoute } from './modules/projects/routing.module';
import { AppUserRoutingModule, EUserRoute } from './modules/user/routing.module';

AppProjectsRoutingModule.loadRoutes();
AppUserRoutingModule.loadRoutes();
AppBillingsRoutingModule.loadRoutes();
AppCmsRoutingModule.loadRoutes();

const routes: Routes = [
  {
    path: '',
    component: HomePage,
    canActivate: [AuthGuard, HomeGuard],
  },
  {
    path: 'notifications',
    component: NotificationsPage,
    canActivate: [AuthGuard],
  },
  {
    path: 'redirect',
    component: RedirectPage,
    canActivate: [AuthGuard],
  },
  {
    path: CamRoutes.getUrl([EProjectsRoute.projects]),
    loadChildren: () => import('./modules/projects/projects.module').then(m => m.AppProjectsModule),
    canActivate: [AuthGuard],
  },
  {
    path: CamRoutes.getUrl([EBillingsRoute.billing]),
    loadChildren: () => import('./modules/billings/billings.module').then(m => m.AppBillingsModule),
    canActivate: [AuthGuard],
  },
  {
    path: CamRoutes.getUrl([EUserRoute.myProfile]),
    loadChildren: () => import('./modules/user/user.module').then(m => m.AppUserModule),
    canActivate: [AuthGuard],
  },
  {
    path: CamRoutes.getUrl([ECmsRoute.cms]),
    loadChildren: () => import('./modules/cms/cms.module').then(m => m.AppCmsModule),
    canActivate: [AuthGuard],
  },
  {
    path: CamRoutes.getUrl([CamMainRoute.USERLOGIN]),
    component: LoginRedirectComponent,
    canActivate: [UnauthenticatedAzureGuard],
  },
  {
    // Needed for handling redirect after login
    path: environment.AADB2C.redirectUri.substring(1),
    component: MsalRedirectComponent,
    canActivate: [UnauthenticatedAzureGuard],
  },
  {
    // azure post sign up
    path: 'auth',
    redirectTo: '',
    canActivate: [AuthGuard],
  },
  {
    path: '**',
    component: NotFoundPage,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
