<cam-card [isNew]="this.invoice.isNew" [shadow]="this.shadow" (click)="this.click.emit()">
  <cam-card-header *ngIf="!this.onlyPayment">
    <cam-card-title>
      <div class="information-container">
        <div class="title">
          <cam-title [level]="3">
            {{ this.invoice.reference }}
          </cam-title>
          <div class="align-center pt-space-sm">
            <div class="date pr-space-md">
              <div class="label">
                {{ 'billings.invoices.due-date' | translate }}
              </div>
              <div class="value">
                {{ this.invoice.dueDate | date : 'shortDate' }}
              </div>
            </div>
            <div class="g-flex">
              <app-billings-payment-status
                class="ml-a"
                [status]="this.invoice.paymentStatus"
                [isCreditNote]="this.invoice.isCreditNote"
              ></app-billings-payment-status>
            </div>
          </div>
        </div>
        <div class="price flex-row">
          <div>
            <div class="label">
              <app-billing-amount [amount]="this.versionPrice" display="label" [forceTTC]="true"></app-billing-amount>
            </div>
            <div class="value amount">
              <app-billing-amount [amount]="this.versionPrice" display="amount" [forceTTC]="true"></app-billing-amount>
            </div>
          </div>
        </div>
      </div>
    </cam-card-title>
  </cam-card-header>
  <cam-card-content class="flex-full">
    <div class="payment-container flex-full">
      <div class="qr-code-container" *ngIf="this.onlyPayment">
        <ng-template [ngTemplateOutlet]="QrCode"></ng-template>
      </div>
      <div class="detail-container">
        <div class="bank-info-container">
          <div class="recipient">
            <div class="label">
              {{ 'billings.invoices.recipient' | translate }}
            </div>
            <div class="value">
              {{ this.invoice.beneficiaryName }}
              <cam-text-to-clipboard [value]="this.invoice.beneficiaryName"></cam-text-to-clipboard>
            </div>
          </div>
          <div class="bank">
            <div class="label">
              {{ 'billings.invoices.bank-info' | translate }}
            </div>
            <div class="value">
              {{ this.invoice.beneficiaryIban }}
              <cam-text-to-clipboard [value]="this.invoice.beneficiaryIban"></cam-text-to-clipboard>
            </div>
          </div>
          <div class="structured-communication">
            <div class="label">
              {{ 'billings.invoices.structured-communication' | translate }}
            </div>
            <div class="value">
              {{ this.removeSpace(this.invoice.structuredCommunication) }}
              <cam-text-to-clipboard [value]="this.invoice.structuredCommunication"></cam-text-to-clipboard>
            </div>
          </div>
        </div>
      </div>
    </div>
  </cam-card-content>
</cam-card>

<ng-template #QrCode>
  <div class="qr-code" *ngIf="this.isWeb$() | async">
    <qrcode [qrdata]="this.qrCodeData()" [width]="100" [errorCorrectionLevel]="'M'" [version]="6"></qrcode>
  </div>
</ng-template>
