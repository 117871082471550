<ng-container *ngLet="this.currentUser$ | async as currentUser">
  <div *ngIf="this.showHeader$ | async">
    <ng-container *ngIf="this.level === 1">
      <cam-layout-header-logo
        [profile]="{ template: profileTemplate, user: currentUser }"
        [askClosing$]="this.askClosing$"
      ></cam-layout-header-logo>
      <ng-template #profileTemplate>
        <cam-my-account (navigateEvent)="this.toMyProfile()" (navigateEditEvent)="this.goToEditProfile()">
        </cam-my-account>
      </ng-template>

      <ng-template #notificationTemplate> Notification template </ng-template>
    </ng-container>
    <ng-container *ngIf="this.level === 2">
      <cam-layout-header-default [showBack]="true" [title]="this.title"></cam-layout-header-default>
    </ng-container>
  </div>
</ng-container>
