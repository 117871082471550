import { HttpErrorResponse } from '@angular/common/http';
import { Component } from '@angular/core';

import { map } from 'rxjs';

import { CamRoutes } from '@camelot/menu';

import { EBillingsRoute } from 'src/app/modules/billings/routing.module';
import { BaseComponent } from 'src/app/modules/core/abstract/baseComponent';
import { PaymentStatus } from 'src/app/services/billings/dto/invoice/payment-status';
import { AppInvoicesService } from 'src/app/services/billings/invoices.service';

@Component({
  selector: 'app-invoice',
  templateUrl: './invoice.component.html',
  styleUrls: ['./invoice.component.scss'],
})
export class InvoiceComponent extends BaseComponent {
  get invoices$() {
    return this._invoiceService.invoices
      .get$()
      .pipe(
        map(invoices =>
          invoices?.filter(
            invoice =>
              invoice.isCreditNote === false &&
              (invoice.paymentStatus === PaymentStatus.Late ||
                invoice.paymentStatus === PaymentStatus.NotPaid ||
                invoice.paymentStatus === PaymentStatus.PartiallyPaid)
          )
        )
      );
  }

  constructor(private _invoiceService: AppInvoicesService) {
    super();
    this._fetch();
  }

  public navigateToInvoice(invoiceId: string) {
    this._router.navigateByUrl(
      CamRoutes.getAbsoluteUrl([EBillingsRoute.billing, EBillingsRoute.invoices, EBillingsRoute.view], {
        id: invoiceId,
      })
    );
  }

  public setRightText(text: string) {
    return {
      text: text,
    };
  }

  private _fetch() {
    this.requestState.asked();
    this._invoiceService.fetchAllInvoices$().subscribe({
      complete: () => this.requestState.completed(),
      error: (error: HttpErrorResponse) => {
        this.requestState.onError(error.status, error.statusText);
      },
    });
  }
}
