import { Component } from '@angular/core';

import { AppBaseFilesPage } from '../abstract';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '',
  templateUrl: './pictures.component.html',
  styleUrls: ['./pictures.component.scss'],
})
export class FilesPicturesPage extends AppBaseFilesPage {
  constructor() {
    super();
  }
}
