import { Component } from '@angular/core';

import { CamRoutes } from '@camelot/menu';

import { EProjectsRoute } from 'src/app/modules/projects/routing.module';

import { AppBaseFilesPage } from '../abstract';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class FilesDashboardPage extends AppBaseFilesPage {
  constructor() {
    super();
  }

  public navigateToDocs() {
    this._router.navigateByUrl(
      CamRoutes.getAbsoluteUrl([EProjectsRoute.projects, EProjectsRoute.view, EProjectsRoute.documents], {
        id: this.projectId,
      })
    );
  }

  public navigateToPics() {
    return;
    this._router.navigateByUrl(
      CamRoutes.getAbsoluteUrl([EProjectsRoute.projects, EProjectsRoute.view, EProjectsRoute.pictures], {
        id: this.projectId,
      })
    );
  }
}
