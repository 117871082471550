import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { QRCodeModule } from 'angularx-qrcode';

import { CamDeviceInfoService } from '@camelot/capacitor';
import { CamStrapiModule } from '@camelot/cms';
import { CamCoreModule, CamGridModule } from '@camelot/core';
import { CamFormModule } from '@camelot/form-basic';
import { CamIconsModule } from '@camelot/icons';
import { CamCardModule, CamContainerModule, CamLayoutModule, CamListModule, CamUiModule } from '@camelot/ui';
import { CamDirectivePipeModule } from '@camelot/utils';

import { AmountComponent } from './components/amount/amount.component';
import { PaymentInformationComponent } from './components/card/payment-information/payment-information.component';
import { ComplexAmountComponent } from './components/complex-amount/complex-amount.component';
import { RefHeaderComponent } from './components/grid/billing-ref-header/billing-ref-header.component';
import { FullWidthCellCommentComponent } from './components/grid/full-width-cell-comment/full-width-cell-comment.component';
import {
  BillingAmountCellComponent,
  BillingAmountHeaderComponent,
  BillingDueDateCellComponent,
  BillingNameCellComponent,
  BillingNbDocumentsCellComponent,
  BillingUnitCellComponent,
} from './components/grid/grid-cell-template';
import { InvoicesListComponent } from './components/invoices/invoices-list/invoices-list.component';
import { PaymentStatusComponent } from './components/invoices/payment-status/payment-status.component';
import { BillingsItemTypeStatusComponent } from './components/item-type-status/item-type-status.component';
import { QuotationCardComponent } from './components/quotations/card/quotation-card.component';
import { LoadingComponent } from './components/quotations/loading/loading.component';
import { QuotationStatusComponent } from './components/quotations/status/quotation-status.component';
import { QuotationVersionStatusComponent } from './components/quotations/status/version/quotation-version-status.component';
import { RefusedComponent } from './components/quotations/status/version/refused/refused.component';
import { SignedComponent } from './components/quotations/status/version/signed/signed.component';
import { QuotationVersionCardComponent } from './components/quotations/version/card/card.component';

@NgModule({
  declarations: [
    AmountComponent,
    BillingAmountCellComponent,
    BillingAmountHeaderComponent,
    BillingDueDateCellComponent,
    BillingNameCellComponent,
    BillingNbDocumentsCellComponent,
    BillingsItemTypeStatusComponent,
    BillingUnitCellComponent,
    ComplexAmountComponent,
    FullWidthCellCommentComponent,
    InvoicesListComponent,
    LoadingComponent,
    PaymentInformationComponent,
    PaymentStatusComponent,
    QuotationCardComponent,
    QuotationStatusComponent,
    QuotationVersionCardComponent,
    QuotationVersionStatusComponent,
    RefHeaderComponent,
    RefusedComponent,
    SignedComponent,
  ],
  imports: [
    CamCardModule,
    CamContainerModule,
    CamCoreModule,
    CamDirectivePipeModule,
    CamFormModule,
    CamGridModule,
    CamIconsModule,
    CamLayoutModule,
    CamListModule,
    CamStrapiModule,
    CamUiModule,
    CommonModule,
    QRCodeModule,
  ],
  exports: [
    AmountComponent,
    BillingAmountCellComponent,
    BillingAmountHeaderComponent,
    BillingDueDateCellComponent,
    ComplexAmountComponent,
    InvoicesListComponent,
    LoadingComponent,
    PaymentInformationComponent,
    PaymentStatusComponent,
    QuotationCardComponent,
    QuotationStatusComponent,
    QuotationVersionCardComponent,
    QuotationVersionStatusComponent,
  ],
  providers: [CamDeviceInfoService],
})
export class AppCoreBillingsModule {}
