import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';

import { IServiceDocument } from 'src/app/modules/core/files/components/container/documents/documents.component';
import { AppDocumentsService } from 'src/app/services/files/documents.service';

import { AppBaseFilesPage } from '../abstract';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.scss'],
})
export class FilesDocumentsPage extends AppBaseFilesPage implements OnInit {
  public selectionId: string | null = null;

  public service: IServiceDocument = {
    get$: () => this._documentService.documentByProject.get$(this.projectId),
  };
  constructor(private _documentService: AppDocumentsService) {
    super();

    this._fetch();
  }

  ngOnInit() {
    this._registerSubscription(
      this._getQueryParams({ selection: '' }).subscribe(({ selection }) => {
        this.selectionId = selection;
      })
    );
  }

  private _fetch() {
    this.requestState.asked();
    this._documentService.fetchDocumentByProjects$(this.projectId).subscribe({
      complete: () => this.requestState.completed(),
      error: (error: HttpErrorResponse) => {
        this.requestState.onError(error.status, error.statusText);
      },
    });
  }
}
