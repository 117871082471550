export interface BillingItem {
  id: string;
  description: string;
  dueDate: string;

  rowsCount: number;

  downloadUriPDF: string;

  devisorId: number;

  exclVatTotal: number;
  inclVatTotal: number;

  isNew: boolean;
}

export const billingItemProps: (keyof BillingItem)[] = [
  'id',
  'description',
  'dueDate',
  'downloadUriPDF',
  'devisorId',
  'rowsCount',
  'exclVatTotal',
  'inclVatTotal',
  'isNew',
];
