import { Component, EventEmitter, Input, Output } from '@angular/core';

import { CamRoutes } from '@camelot/menu';

import { AppProjectsService } from 'src/app/services/projects/projects.service';

import { EBillingsRoute } from '../../../billings/routing.module';
import { BaseComponent } from '../../../core/abstract/baseComponent';
import { EProjectsRoute } from '../../../projects/routing.module';

@Component({
  selector: 'app-notification-list',
  templateUrl: './notification-list.component.html',
  styleUrls: ['./notification-list.component.scss'],
})
export class NotificationListComponent extends BaseComponent {
  @Input()
  title: string = 'base.notification.title';

  @Input()
  iconName: string | null = null;

  @Input()
  type: 'both' | 'new' | 'old' = 'both';

  @Input()
  take: number | null = null;

  @Input()
  ctaTitle: string | null = null;

  @Output()
  numberOfNotifications = new EventEmitter<number>();

  @Output()
  cta = new EventEmitter<void>();

  public services = {
    getProjects$: (ids: string[]) => this._projectService.getProjectsLightInfo$(ids),
  };
  public routing = {
    project: (data: { projectId: string }) => {
      this._router.navigateByUrl(
        CamRoutes.getAbsoluteUrl([EProjectsRoute.projects, EProjectsRoute.view, EProjectsRoute.summary], {
          id: data.projectId,
        })
      );
    },
    invoice: (data: { projectId: string; invoiceId: string }) => {
      this._router.navigateByUrl(
        CamRoutes.getAbsoluteUrl([EBillingsRoute.billing, EBillingsRoute.invoices, EBillingsRoute.view], {
          id: data.invoiceId,
        })
      );
    },
    quotationVersion: (data: { projectId: string; quotationId: string; quotationVersionId: string }) => {
      this._router.navigateByUrl(
        CamRoutes.getAbsoluteUrl([EBillingsRoute.billing, EBillingsRoute.quotations, EBillingsRoute.view], {
          id: data.quotationId,
          version: data.quotationVersionId,
        })
      );
    },
  };

  public number: number = 0;

  get isNew() {
    switch (this.type) {
      case 'new':
        return true;
      case 'old':
        return false;
      default:
        return null;
    }
  }
  constructor(private _projectService: AppProjectsService) {
    super();
  }

  public goToCta() {
    this.cta.emit();
  }
  public updateNb(nb: number) {
    this.number = nb;
  }
}
