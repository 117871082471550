<div class="price-information">
  <app-billing-amount class="label" [amount]="this.amount" display="label"></app-billing-amount>
  <app-billing-amount class="price" [amount]="this.amount" display="amount"></app-billing-amount>
  <div class="small-information">
    <app-billing-amount [amount]="this.amount" display="amount" [inverse]="true"></app-billing-amount>
    <app-billing-amount
      class="small-information-label"
      [amount]="this.amount"
      display="label"
      [inverse]="true"
    ></app-billing-amount>
  </div>
</div>
