import { Component, Inject, inject } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';
import { Event, NavigationEnd, NavigationError, NavigationStart } from '@angular/router';

import { GoogleTagManagerService } from 'angular-google-tag-manager';

import { CamDeviceInfoService } from '@camelot/capacitor';

import { BaseComponent } from './modules/core/abstract/baseComponent';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent extends BaseComponent {
  title = 'lancelot';
  //  private _authService = inject(CamAuthService<any>);

  constructor(
    private _deviceInfoService: CamDeviceInfoService,
    private _gtmService: GoogleTagManagerService,
    private _dialogService: MatDialog,
    private _bottomSheetService: MatBottomSheet
  ) {
    super();

    //    this._authService.load();
    this.setBodyClasses();

    this._router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
        window.scrollTo(0, 0);
        this._dialogService.closeAll();
        this._bottomSheetService.dismiss();
        return;
      }
      if (event instanceof NavigationEnd) {
        const gtmTag = {
          event: 'page',
          pageName: event.url,
        };

        this._gtmService.pushTag(gtmTag);
      }
      if (event instanceof NavigationError) {
        //
      }
    });
  }

  private setBodyClasses() {
    this._deviceInfoService.deviceClasses$.subscribe(classes => {
      for (let classToAdd of classes.filter(x => x.length > 0)) document.body.classList.add(classToAdd);
    });
  }
}
