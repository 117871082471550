import { Component, EventEmitter, Input, Output } from '@angular/core';

import { map, of } from 'rxjs';

import { CamDeviceInfoService } from '@camelot/capacitor';

import { BaseComponent } from 'src/app/modules/core/abstract/baseComponent';
import { Invoice } from 'src/app/services/billings/dto/invoice/invoice';

import { IPrice } from '../../../helpers/price';

@Component({
  selector: 'app-card-payment-information',
  templateUrl: './payment-information.component.html',
  styleUrls: ['./payment-information.component.scss'],
})
export class PaymentInformationComponent extends BaseComponent {
  @Input()
  invoice!: Invoice;

  @Input()
  onlyPayment = false;

  @Input()
  shadow = true;

  @Output()
  // eslint-disable-next-line @angular-eslint/no-output-native
  click: EventEmitter<void> = new EventEmitter();

  get versionPrice(): IPrice {
    return {
      excl: this.invoice.exclVatTotal,
      incl: this.invoice.inclVatTotal,
    };
  }

  constructor(public deviceInfo: CamDeviceInfoService) {
    super();
  }

  public removeSpace(value: string) {
    if (!value) {
      return '';
    }
    return value.replace(/\s/g, '');
  }
  public isMobileOs$() {
    return this.deviceInfo.os$.pipe(map(value => this.deviceInfo.isMobileOs(value)));
  }
  public isWeb$() {
    return this.deviceInfo.os$.pipe(map(value => !this.deviceInfo.isMobileOs(value)));
  }

  public qrCodeData() {
    return `BCD\n002\n2\nSCT\nPCHQBEBB\n${this.invoice.beneficiaryName}\n${this.invoice.beneficiaryIban}\nEUR${this.invoice.inclVatTotal}\n \n${this.invoice.structuredCommunication}`;
  }
}
