import { Component, Input } from '@angular/core';

import { Observable } from 'rxjs';

import { CamRoutes } from '@camelot/menu';

import { EBillingsRoute } from 'src/app/modules/billings/routing.module';
import { BaseComponent } from 'src/app/modules/core/abstract/baseComponent';
import { IPrice } from 'src/app/modules/core/billings/helpers/price';
import { Quotation } from 'src/app/services/billings/dto/quotation/quotation';
import { QuotationVersion } from 'src/app/services/billings/dto/quotation/version';

@Component({
  selector: 'app-quotations-list',
  templateUrl: './quotations-list.component.html',
  styleUrls: ['./quotations-list.component.scss'],
})
export class QuotationsListComponent extends BaseComponent {
  @Input()
  quotations!: Quotation[];

  public navigateToDetail(quotationId: string, versionId?: string) {
    this._router.navigateByUrl(
      CamRoutes.getAbsoluteUrl([EBillingsRoute.billing, EBillingsRoute.quotations, EBillingsRoute.view], {
        id: quotationId,
        version: versionId ?? 'latest',
      })
    );
  }

  public getPrice(version: QuotationVersion): IPrice {
    return {
      excl: version.exclVatTotal,
      incl: version.inclVatTotal,
    };
  }
}
