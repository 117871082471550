import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class AppRedirectService {
  private readonly storageKey = 'lancelotRedirectUrlPath';

  setUrlPath(urlPath: string | null): void {
    if (urlPath === null) {
      localStorage.removeItem(this.storageKey);
    } else {
      localStorage.setItem(this.storageKey, urlPath);
    }
  }

  getUrlPath(): string | null {
    return localStorage.getItem(this.storageKey);
  }
}
