<cam-loader [isLoading]="this.requestState.isLoading()" *ngLet="this.invoices$ | async as invoices">
  <cam-error [message]="this.requestState.getErrorMessage()" [code]="this.requestState.getErrorStatus()">
    <cam-empty
      [isEmpty]="!invoices || invoices.length === 0"
      [isLight]="false"
      text="billings.no-invoice"
      icon="ghost"
      iconSize="xl"
    >
      <div class="invoices-lists" *ngIf="invoices && invoices.length > 0 && !this.requestState.isLoading()">
        <div class="to-pay-container" *ngLet="this.invoicesToPay$ | async as invoicesToPay">
          <cam-title [level]="2">{{ 'billings.invoices.list.to-pay-title' | translate }}</cam-title>
          <cam-empty
            [isEmpty]="!invoicesToPay || invoicesToPay.length === 0"
            [isLight]="false"
            icon="checked"
            text="billings.invoices.to-pay.empty"
            iconSize="lg"
          >
            <div class="list-cards-container">
              <div *ngFor="let invoice of invoicesToPay">
                <app-card-payment-information
                  [invoice]="invoice"
                  (click)="this.navigateToDetail(invoice.id)"
                ></app-card-payment-information>
              </div>
            </div>
          </cam-empty>
        </div>

        <div class="previous-invoices-container" *ngLet="this.invoicesPaid$ | async as invoicesPaid">
          <div *ngIf="invoicesPaid && this.invoicesPaid.length > 0">
            <cam-title [level]="2">{{ 'billings.invoices.list.previous-invoices-title' | translate }}</cam-title>
            <app-invoices-list [invoices]="invoicesPaid"></app-invoices-list>
          </div>
        </div>
        <div class="credit-notes-container" *ngLet="this.creditNotes$ | async as creditNotes">
          <div *ngIf="creditNotes && this.creditNotes.length > 0">
            <cam-title [level]="2">{{ 'billings.invoices.list.credit-notes-title' | translate }}</cam-title>
            <app-invoices-list [invoices]="creditNotes"></app-invoices-list>
          </div>
        </div>
      </div>
    </cam-empty>
  </cam-error>
</cam-loader>
