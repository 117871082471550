<cam-layout-page>
  <cam-layout-header>
    <app-header [level]="2" [title]="this.currentRouteName"></app-header>
  </cam-layout-header>

  <cam-layout-title>
    <div class="layout-title-container">
      <cam-font-icon
        *ngIf="!this.isMobile"
        class="pr-space-md"
        name="arrow-back"
        (click)="this.goBack()"
      ></cam-font-icon>
      <cam-title [level]="this.isMobile ? 4 : 2">
        <ng-content select="app-layout-title"></ng-content>
      </cam-title>
    </div>
  </cam-layout-title>

  <cam-layout-content>
    <ng-content select="app-layout-content"></ng-content>
  </cam-layout-content>

  <cam-layout-nav>
    <ng-container *ngIf="this.breakpoints.isDesktop$ | async">
      <app-main-menu></app-main-menu>
    </ng-container>
  </cam-layout-nav>
</cam-layout-page>
