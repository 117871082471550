import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';

import { Observable, map } from 'rxjs';

import { Permissions } from '@camelot/user';

@Injectable({
  providedIn: 'root',
})
export class UnauthenticatedAzureGuard {
  constructor(private router: Router) {}
  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
    if (!Permissions.received) {
      return Permissions.updated$.pipe(
        map(() => {
          if (!Permissions.isAuthenticated) {
            return true;
          } else {
            this.setRedirect();
            return false;
          }
        })
      );
    }
    if (Permissions.isAuthenticated) {
      this.setRedirect();
      return false;
    }
    return true;
  }

  public setRedirect(): void {
    this.router.navigateByUrl('');
  }
}
