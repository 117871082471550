import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { CamIconsModule } from '@camelot/icons';
import { CamCardModule, CamUiModule } from '@camelot/ui';
import { CamDirectivePipeModule } from '@camelot/utils';

import { ProjectCardComponent } from './components/card/project-card.component';
import { ProjectAlternativeCardComponent } from './components/project-alternative-card/project-alternative-card.component';
import { ProjectStatusComponent } from './components/status/project-status.component';

@NgModule({
  declarations: [ProjectStatusComponent, ProjectAlternativeCardComponent, ProjectCardComponent],
  imports: [CamDirectivePipeModule, CamUiModule, CommonModule, CamCardModule, CamIconsModule],
  exports: [ProjectStatusComponent, ProjectAlternativeCardComponent, ProjectCardComponent],
})
export class AppCoreProjectsModule {}
