import { Component } from '@angular/core';

import { CamRoutes } from '@camelot/menu';

import { BaseComponent } from 'src/app/modules/core/abstract/baseComponent';
import { EProjectsRoute } from 'src/app/modules/projects/routing.module';

@Component({
  selector: 'app-home-contacts',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.scss'],
})
export class ContactsComponent extends BaseComponent {
  public menu = [];

  public goToContacts() {
    this._router.navigateByUrl(CamRoutes.getAbsoluteUrl([EProjectsRoute.projects, EProjectsRoute.list]));
  }
}
