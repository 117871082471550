import { ItemType } from './item-type';
import { RowType } from './row-type';
import { Unit } from './unit';

export interface BillingRow {
  id: string;
  index: number;
  parent: string;
  hidden: boolean;
  rowType: RowType;
  sourceRef: string;
  description: string;
  itemType: ItemType;
  quantity: number;
  unit: Unit;
  unitDescription: string;
  unitSellingPrice: number;
  vatPercentage: number;
  exclVatTotal: number;
  inclVatTotal: number;
  chapterExclVatTotal: number;
  chapterInclVatTotal: number;
  childrenRowsCount: number;
  documentIds?: string[];

  /* set by service */
  label: string;
}

export const billingRowProps: (keyof BillingRow)[] = [
  'id',
  'index',
  'parent',
  'hidden',
  'rowType',
  'sourceRef',
  'description',
  'itemType',
  'quantity',
  'unit',
  'unitDescription',
  'unitSellingPrice',
  'vatPercentage',
  'exclVatTotal',
  'inclVatTotal',
  'chapterExclVatTotal',
  'chapterInclVatTotal',
  'childrenRowsCount',
];
