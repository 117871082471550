import { Component } from '@angular/core';

import { AbstractCellComponent, AbstractHeaderComponent } from '@camelot/core';
import { percentage } from '@camelot/utils';

import { ItemType } from 'src/app/services/billings/dto/common/item-type';
import { BillingRow } from 'src/app/services/billings/dto/common/row';
import { RowType } from 'src/app/services/billings/dto/common/row-type';
import { Unit } from 'src/app/services/billings/dto/common/unit';

import { IPrice } from '../../helpers/price';

/** Header */
@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '',
  template: `<cam-ag-grid-base-header class="ml-a" [params]="this.params">
    <app-billing-amount style="line-height: normal;" display="label"></app-billing-amount>
  </cam-ag-grid-base-header>`,
})
export class BillingAmountHeaderComponent extends AbstractHeaderComponent<any, any> {}

/** Cell */
@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '',
  template: `<div *ngIf="!this.isComment" class="flex-row g-space-md">
      {{ this.value }}
      <app-billings-item-type-status [type]="this.itemType"></app-billings-item-type-status>
    </div>
    <div *ngIf="this.isComment" class="align-center g-space-md">
      <cam-font-icon name="infos" type="sm"></cam-font-icon>
      <cam-expandable-text>
        <div class="fs-font-body-sm-default-size">
          {{ this.value }}
        </div>
      </cam-expandable-text>
    </div>`,
})
export class BillingNameCellComponent extends AbstractCellComponent<BillingRow, string> {
  get isComment() {
    return this._item?.rowType === RowType.Comment;
  }
  get itemType() {
    return this._item?.itemType || ItemType.Unknown;
  }
}

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '',
  template: `<app-quotation-loading class="ml-a" *ngIf="this.value">
    <app-billing-amount style="line-height: normal; font-weight: bold" [amount]="this.value" display="amount">
    </app-billing-amount>
  </app-quotation-loading>`,
})
export class BillingAmountCellComponent extends AbstractCellComponent<any, IPrice> {}

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '',
  template: `{{ this.unitName | translate }}`,
})
export class BillingUnitCellComponent extends AbstractCellComponent<BillingRow, Unit> {
  get unitName() {
    if (!this.value) {
      return '';
    }
    if (this.value === Unit.Other) {
      return this._item?.unitDescription ?? '';
    }
    return `billings.units.${Unit[this.value].toLocaleLowerCase()}`;
  }
}

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '',
  styles: ['.cell-container { position: relative; }', 'cam-bullet { position: absolute; bottom: -10px; right: -5px }'],
  template: `
    <div class="cell-container align-center" *ngIf="this.value">
      <cam-font-icon name="doc"></cam-font-icon>
      <cam-bullet type="notif" size="md">{{ this.value }}</cam-bullet>
    </div>
  `,
})
export class BillingNbDocumentsCellComponent extends AbstractCellComponent<BillingRow, number> {}

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '',
  template: `{{ this.quantityName }}`,
})
export class BillingQuantityCellComponent extends AbstractCellComponent<BillingRow, number> {
  get quantityName() {
    if (this._item?.itemType === ItemType.Reduction) {
      return `${percentage(this.value ?? 0, 1)}%`;
    }
    return this.value ? this.value : '';
  }
}

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '',
  template: `{{ this.value | date : 'shortDate' }}`,
})
export class BillingDueDateCellComponent extends AbstractCellComponent<any, string> {}
