<cam-layout-page>
  <cam-layout-header>
    <!-- <app-header></app-header> -->
  </cam-layout-header>

  <cam-layout-title>
    <cam-title>
      <ng-content select="app-layout-title"></ng-content>
    </cam-title>
  </cam-layout-title>

  <cam-layout-content>
    <ng-content select="app-layout-content"></ng-content>
  </cam-layout-content>

  <cam-layout-nav>
    <app-main-menu></app-main-menu>
  </cam-layout-nav>
</cam-layout-page>
