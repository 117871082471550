import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { CamIconsModule } from '@camelot/icons';
import { CamLayoutModule, CamUiModule } from '@camelot/ui';

import { AppCommonModule } from '../common/common.module';
import { AppMenuModule } from '../menu/menu.module';
import { LayoutContentComponent } from './layout-content/layout-content.component';
import { LayoutFirstLevelComponent } from './layout-first-level/layout-first-level.component';
import { LayoutSecondLevelComponent } from './layout-second-level/layout-second-level.component';
import { LayoutTitleComponent } from './layout-title/layout-title.component';

@NgModule({
  declarations: [LayoutContentComponent, LayoutFirstLevelComponent, LayoutSecondLevelComponent, LayoutTitleComponent],
  imports: [AppCommonModule, AppMenuModule, CamIconsModule, CamLayoutModule, CamUiModule, CommonModule],
  exports: [LayoutContentComponent, LayoutFirstLevelComponent, LayoutSecondLevelComponent, LayoutTitleComponent],
})
export class AppLayoutModule {}
