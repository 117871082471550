import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { Quotation } from 'src/app/services/billings/dto/quotation/quotation';
import { QuotationStatus } from 'src/app/services/billings/dto/quotation/quotation-status';

import { IPrice } from '../../../helpers/price';

@Component({
  selector: 'app-quotation-card',
  templateUrl: './quotation-card.component.html',
  styleUrls: ['./quotation-card.component.scss'],
})
export class QuotationCardComponent implements OnInit {
  @Input()
  quotation!: Quotation;

  @Output()
  // eslint-disable-next-line @angular-eslint/no-output-native
  click: EventEmitter<void> = new EventEmitter();

  public amount!: IPrice;

  get showDate() {
    return this.quotation.status === QuotationStatus.Sent || this.quotation.status === QuotationStatus.Expired;
  }
  get isNew() {
    return !!this.quotation.versions?.find(version => version.isNew);
  }
  ngOnInit() {
    this.amount = { excl: this.quotation.exclVatTotal, incl: this.quotation.inclVatTotal };
  }
}
